<template>
  <input
    v-bind="$attrs"
    :value="modelValue"
    :class="{'theme-border-color': focused}"
    class="base-input"
    @input="$emit('update:modelValue', $event.target.value)"
    @focus="focus"
    @blur="blur"
  >
</template>

<script>
export default {
  name: 'Input',
  props: {
    modelValue: {
      type: [String, Number], // Can be a number en case of type=number input (and even an object? in case of fileInput?)
      default: ''
    }
  },
  emits: ['blur', 'focus', 'update:modelValue'],
  data () {
    return {
      focused: false
    }
  },
  methods: {
    blur () {
      this.focused = false
      this.$emit('blur')
    },
    focus () {
      this.focused = true
      this.$emit('focus')
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
