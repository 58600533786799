<template>
  <div
    v-click-outside="close"
    class="base-autocomplete"
  >
    <div
      v-if="filtered"
      class="filter"
    >
      <input
        ref="filter"
        v-model="filter"
        :placeholder="placeholder"
      >
    </div>
    <ul class="suggestion-list">
      <li
        v-for="(item, index) in filteredList"
        :key="index"
        class="item"
        :class="{'highlight': (index === highlightedIndex)}"
        @click="onSelect(item)"
      >
        <i
          v-if="item.icon"
          :class="item.icon"
        />
        {{ getDisplayValue(item) }} <slot />
      </li>
    </ul>
  </div>
</template>

<script>
import { directive } from 'vue3-click-away'
import { sortWithString, filter as filterUtil } from '@utils/array.util'

export default {
  name: 'Autocomplete',
  directives: {
    'click-outside': directive
  },
  props: {
    list: {
      type: Array,
      default: () => []
    },
    displayField: {
      type: String,
      default: undefined
    },
    filtered: {
      type: Boolean,
      default: false
    },
    highlightedIndex: {
      type: Number,
      default: -1
    },
    input: {
      type: String,
      default: undefined
    },
    reversed: {
      type: Boolean,
      default: false
    },
    sort: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: 'Filter'
    }
  },
  emits: ['select', 'close'],
  data () {
    return {
      filter: ''
    }
  },
  computed: {
    sortedList () {
      if (!this.sort) {
        return this.list
      }
      return sortWithString(this.list, this.reversed, this.displayField)
    },
    filteredList () {
      var filter = this.input || this.filter
      return filterUtil(filter, this.sortedList, this.displayField)
    }
  },
  created () {
    if (this.filtered) {
      this.$nextTick(() => this.$refs.filter.focus())
    }
  },
  methods: {
    getDisplayValue (item) {
      if (this.displayField === undefined) {
        return item
      } else {
        return item[this.displayField]
      }
    },
    onSelect (item) {
      this.$emit('select', item)
      this.filter = ''
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'src/design/common';

.base-autocomplete {
  background-color: $background-white-color;
  overflow-y: auto;
  max-height: 300px;
  position: absolute;
  min-width: 100%;
  z-index: $dropdown-z-index;

  .suggestion-list {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .filter {
    padding: 3px 20px;

    input {
      width: 99%;

      &:focus {
        outline: none;
      }
    }
  }

  .item {
    @extend %no-text-highlight;
    display: block;
    padding: 3px 20px;
    white-space: nowrap;
    cursor: pointer;

    &:hover,
    &.highlight {
      background-color: $background-hover-color;
    }
  }

  .fa,
  .fas {
    margin-right: 5px;
  }
}
</style>
