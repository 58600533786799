import { sortWithString } from '@/utils/array.util'
import libraryService from '@/api/indicator/library.service'

export const state = {
  indicatorList: undefined
}

export const mutations = {
  addIndicator (state, indicator) {
    state.indicatorList.push(indicator)
  },
  deleteIndicator (state, indicatorId) {
    const i = state.indicatorList.map(item => item.indicatorId).indexOf(indicatorId)
    state.indicatorList.splice(i, 1)
  },
  setIndicatorList (state, indicatorList) {
    state.indicatorList = indicatorList
  },
  updateIndicator (state, { indicatorId, title, shortDescription, longDescription, dimensions, shortLicence, shortSpdxLicence, longLicence, version, localUrl, publicUrl, date, gitHashKey, urlParams, urlParamsSchema, isHidden, thumbnailUrl }) {
    const i = state.indicatorList.map(item => item.indicatorId).indexOf(indicatorId)
    state.indicatorList[i].title = title
    state.indicatorList[i].shortDescription = shortDescription
    state.indicatorList[i].longDescription = longDescription
    state.indicatorList[i].dimensions = dimensions
    state.indicatorList[i].shortLicence = shortLicence
    state.indicatorList[i].shortSpdxLicence = shortSpdxLicence
    state.indicatorList[i].longLicence = longLicence
    state.indicatorList[i].version = version
    state.indicatorList[i].localUrl = localUrl
    state.indicatorList[i].publicUrl = publicUrl
    state.indicatorList[i].date = date
    state.indicatorList[i].gitHashKey = gitHashKey
    state.indicatorList[i].urlParams = urlParams
    state.indicatorList[i].urlParamsSchema = urlParamsSchema
    state.indicatorList[i].isHidden = isHidden
    state.indicatorList[i].thumbnailUrl = thumbnailUrl
  }
}

export const actions = {
  addIndicator ({ commit }, { title, shortDescription, longDescription, dimensions, shortLicence, shortSpdxLicence, longLicence, version, localUrl, publicUrl, date, gitHashKey, urlParams, urlParamsSchema, isHidden, imageBlob }) {
    libraryService.addIndicator(title, shortDescription, longDescription, dimensions, shortLicence, shortSpdxLicence, longLicence, version, localUrl, publicUrl, date, gitHashKey, urlParams, urlParamsSchema, isHidden, imageBlob)
      .then(data => {
        if (data.success) {
          commit('addIndicator', data.indicator)
        } else {
          throw new Error()
        }
      })
      .catch(err => {
        this.dispatch('popups/pushPopup', { message: 'ERROR' })
        console.error(err)
      })
  },
  deleteIndicator ({ commit }, indicatorId) {
    libraryService.deleteIndicator(indicatorId)
      .then(data => {
        if (data.success) {
          commit('deleteIndicator', indicatorId)
          this.dispatch('catalog/deleteIndicatorFromLoadedCatalogs', indicatorId)
        } else {
          throw new Error()
        }
      })
      .catch(err => {
        this.dispatch('popups/pushPopup', { message: 'ERROR' })
        console.error(err)
      })
  },
  initIndicatorList ({ commit }) {
    libraryService.getIndicatorList()
      .then(data => {
        if (data.success) {
          commit('setIndicatorList', data.indicatorList)
        } else {
          throw new Error()
        }
      })
      .catch(err => {
        this.dispatch('popups/pushPopup', { message: 'ERROR' })
        console.error(err)
      })
  },
  updateIndicator ({ commit }, { indicatorId, title, shortDescription, longDescription, dimensions, shortLicence, shortSpdxLicence, longLicence, version, localUrl, publicUrl, date, gitHashKey, urlParams, urlParamsSchema, isHidden, imageBlob }) {
    libraryService.updateIndicator(indicatorId, title, shortDescription, longDescription, dimensions, shortLicence, shortSpdxLicence, longLicence, version, localUrl, publicUrl, date, gitHashKey, urlParams, urlParamsSchema, isHidden, imageBlob)
      .then(data => {
        if (data.success) {
          commit('updateIndicator', { indicatorId, title, shortDescription, longDescription, dimensions, shortLicence, shortSpdxLicence, longLicence, version, localUrl, publicUrl, date, gitHashKey, urlParams, urlParamsSchema, isHidden, thumbnailUrl: data.indicator.thumbnailUrl })
          this.dispatch('catalog/updateIndicatorFromLoadedCatalogs', { indicatorId, title, shortDescription, longDescription, dimensions, shortLicence, shortSpdxLicence, longLicence, version, localUrl, publicUrl, date, gitHashKey, urlParams, urlParamsSchema, isHidden, thumbnailUrl: data.indicator.thumbnailUrl })
        } else {
          throw new Error()
        }
      })
      .catch(err => {
        this.dispatch('popups/pushPopup', { message: 'ERROR' })
        console.error(err)
      })
  }
}

export const getters = {
  sortedIndicatorList (state) {
    return (state.indicatorList === undefined) ? undefined : sortWithString(state.indicatorList, false, 'title')
  }
}
