import spacesStructure from '@/utils/spaces.structure'
import utils from '@/utils/utils'

export const state = {
  privateFields: [],
  trashFields: [],
  trashSubFolderFields: []
}

export const mutations = {
  updatePrivateFields (state, payload) {
    state.privateFields = [...payload]
    const contextMenuOptions = utils.formatOptions(payload)
    this.dispatch('contextMenu/setContextMenuOptions', contextMenuOptions)
  }
}

export const actions = {
  initFields ({ commit }) {
    // initialise fields with defaults values
    this.dispatch('fileFields/resetPrivateFields')
  },
  resetPrivateFields ({ commit }) {
    const fields = [...spacesStructure.private.fields]
    for (let i = 0; i < fields.length; ++i) {
      fields[i].isSelected = fields[i].defaultDisplay
    }
    commit('updatePrivateFields', fields)
  },
  updatePrivateOption ({ commit }, option) {
    const fields = [...this.state.fileFields.privateFields]
    let find = false
    for (let i = 0; i < fields.length; ++i) {
      if (fields[i].name === option) {
        find = true
        fields[i].isSelected = !fields[i].isSelected
        commit('updatePrivateFields', fields)
        break
      }
    }
    if (!find) { console.error('unknown option', option, 'in', this.state.fileFields.privateFields) }
  }
}
