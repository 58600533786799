import axios from 'axios'
import constants from '@/constants/appConstants'
import { getCookie } from '@/utils/browser.util'

export default {
  createCommunity,
  updateCommunity,
  deleteCommunity,
  leaveCommunity,
  isCommunityNameAvailable,
  getUserCommunities,
  getAllCadycoUsers
}

const COMMUNITIES_PATH = '/communities.communities'

/**
 * Create a Community, return the created community
 */
function createCommunity (name, description, memberIds) {
  return axios.get(constants.JSON_WS_URL + COMMUNITIES_PATH + '/create-community', {
    params: {
      p_auth: getCookie('pauth'),
      name: name,
      description: description,
      memberIds: JSON.stringify(memberIds)
    }
  }).then(response => response.data)
}

/**
 * Update a Community (fields: success, community)
 */
function updateCommunity (communityId, name, description, memberIds) {
  return axios.get(constants.JSON_WS_URL + COMMUNITIES_PATH + '/update-community', {
    params: {
      p_auth: getCookie('pauth'),
      communityId: communityId,
      name: name,
      description: description,
      memberIds: JSON.stringify(memberIds)
    }
  }).then(response => response.data)
}

/**
 * Delete a Community by id
 */
function deleteCommunity (communityId) {
  return axios.get(constants.JSON_WS_URL + COMMUNITIES_PATH + '/delete-community', {
    params: {
      p_auth: getCookie('pauth'),
      communityId: communityId
    }
  }).then(response => response.data)
}

/**
 * Update a community member list by removing current user (fields: success)
 */
function leaveCommunity (communityId) {
  return axios.get(constants.JSON_WS_URL + COMMUNITIES_PATH + '/leave-community', {
    params: {
      p_auth: getCookie('pauth'),
      communityId: communityId
    }
  }).then(response => response.data)
}

/**
 * get Communities where the current user is member
 */
function getUserCommunities () {
  return axios.get(constants.JSON_WS_URL + COMMUNITIES_PATH + '/get-user-communities', {
    params: {
      p_auth: getCookie('pauth')
    }
  }).then(response => response.data)
}

/**
 * Check if a community with the same name already exist on the platform (fields: success, isAvailable)
 */
function isCommunityNameAvailable (name) {
  return axios.get(constants.JSON_WS_URL + COMMUNITIES_PATH + '/is-community-name-available', {
    params: {
      p_auth: getCookie('pauth'),
      name: name
    }
  }).then(response => response.data)
}

// /**
//  * Get all users of cadycoPlatform (fields: success, cadycoUsers)
//  */
// function getAllCadycoUsers () {
//   return axios.get(constants.JSON_WS_URL + COMMUNITIES_PATH + '/get-users-to-pick', {
//     params: {
//       p_auth: getCookie('pauth')
//     }
//   }).then(response => response.data)
// }

/**
 * Get all users of cadycoPlatform (fields: success, cadycoUsers)
 */
function getAllCadycoUsers () {
  return axios.get(constants.JSON_WS_URL + '/contacts.contacts' + '/get-all-cadyco-users', {
    params: {
      p_auth: getCookie('pauth')
    }
  }).then(response => response.data)
}
