import store from '@store/index.js'
import i18n from '@/i18n'
import { sortWithString } from '@/utils/array.util'
import catalogService from '@/api/indicator/catalog.service'
import profileService from '@/api/userInfos/profiles.services'

export const state = {
  // User catalog or use selected catalog ?
  userCatalog: undefined,
  isDefault: true,
  // Catalog admin
  profileList: undefined,
  selectedProfile: undefined,
  profileCatalog: undefined,
  unreadProfileIndicators: []
}

export const mutations = {
  removeNewIndicator (state, indicator) {
    const i = state.unreadProfileIndicators.map(item => item.indicatorId).indexOf(indicator.indicatorId)
    state.unreadProfileIndicators.splice(i, 1)
  },
  removeNewIndicators (state) {
    state.unreadProfileIndicators.splice(0, state.unreadProfileIndicators.length)
  },
  addCategory (state, { catalogId, category }) {
    const catalog = (state.profileCatalog && state.profileCatalog.catalogId === catalogId) ? state.profileCatalog
      : (state.userCatalog && state.userCatalog.catalogId === catalogId) ? state.userCatalog : undefined

    if (catalog !== undefined) {
      catalog.categoryList.push(category)
    }
  },
  addIndicatorInCategory (state, { catalogId, categoryId, indicator }) {
    const catalog = (state.profileCatalog && state.profileCatalog.catalogId === catalogId) ? state.profileCatalog
      : (state.userCatalog && state.userCatalog.catalogId === catalogId) ? state.userCatalog : undefined

    if (catalog !== undefined) {
      const i = catalog.categoryList.map(item => item.categoryId).indexOf(categoryId)
      catalog.categoryList[i].indicatorList.push(indicator)
    }
  },
  deleteCategory (state, { catalogId, categoryId }) {
    const catalog = (state.profileCatalog && state.profileCatalog.catalogId === catalogId) ? state.profileCatalog
      : (state.userCatalog && state.userCatalog.catalogId === catalogId) ? state.userCatalog : undefined

    if (catalog !== undefined) {
      const i = catalog.categoryList.map(item => item.categoryId).indexOf(categoryId)
      catalog.categoryList.splice(i, 1)
    }
  },
  deleteIndicator (state, { catalogId, categoryId, indicatorId }) {
    const catalog = (state.profileCatalog && state.profileCatalog.catalogId === catalogId) ? state.profileCatalog
      : (state.userCatalog && state.userCatalog.catalogId === catalogId) ? state.userCatalog : undefined

    if (catalog !== undefined) {
      const i = catalog.categoryList.map(item => item.categoryId).indexOf(categoryId)
      const j = catalog.categoryList[i].indicatorList.map(item => item.indicatorId).indexOf(indicatorId)
      catalog.categoryList[i].indicatorList.splice(j, 1)
    }
  },
  selectProfile (state, profile) {
    state.selectedProfile = profile
  },
  setProfileList (state, list) {
    state.profileList = list
  },
  setUnreadProfileIndicators (state, list) {
    state.unreadProfileIndicators = list
  },
  selectCatalog (state, catalog) {
    state.profileCatalog = catalog
  },
  setUserCatalog (state, catalog) {
    state.userCatalog = catalog
  },
  updateCategory (state, { catalogId, categoryId, title, position }) {
    const catalog = (state.profileCatalog && state.profileCatalog.catalogId === catalogId) ? state.profileCatalog
      : (state.userCatalog && state.userCatalog.catalogId === catalogId) ? state.userCatalog : undefined

    if (catalog !== undefined) {
      const i = catalog.categoryList.map(item => item.categoryId).indexOf(categoryId)
      catalog.categoryList[i].title = title
      catalog.categoryList[i].position = position

      // move category in array
      catalog.categoryList.splice((position - 1), 0, catalog.categoryList.splice(i, 1)[0])
    }
  },
  updateIndicatorInCategory (state, { catalogId, categoryId, indicatorId, position, isPublic }) {
    const catalog = (state.profileCatalog && state.profileCatalog.catalogId === catalogId) ? state.profileCatalog
      : (state.userCatalog && state.userCatalog.catalogId === catalogId) ? state.userCatalog : undefined

    if (catalog !== undefined) {
      const i = catalog.categoryList.map(item => item.categoryId).indexOf(categoryId)
      const j = catalog.categoryList[i].indicatorList.map(item => item.indicatorId).indexOf(indicatorId)

      catalog.categoryList[i].indicatorList[j].position = position
      catalog.categoryList[i].indicatorList[j].isPublic = isPublic

      // move indicator in array
      catalog.categoryList[i].indicatorList.splice((position - 1), 0, catalog.categoryList[i].indicatorList.splice(j, 1)[0])
    }
  },
  deleteIndicatorFromLoadedCatalogs (state, indicatorId) {
    if (state.profileCatalog !== undefined) {
      state.profileCatalog.categoryList.forEach((category) => {
        const i = category.indicatorList.map(indicator => indicator.indicatorId).indexOf(indicatorId)
        if (i !== -1) {
          category.indicatorList.splice(i, 1)
        }
      })
    }
    if (state.userCatalog !== undefined) {
      state.userCatalog.categoryList.forEach((category) => {
        const i = category.indicatorList.map(indicator => indicator.indicatorId).indexOf(indicatorId)
        if (i !== -1) {
          category.indicatorList.splice(i, 1)
        }
      })
    }
  },
  updateIndicatorFromLoadedCatalogs (state, { indicatorId, title, shortDescription, longDescription, dimensions, shortLicence, shortSpdxLicence, longLicence, version, localUrl, publicUrl, date, gitHashKey, urlParams, urlParamsSchema, isHidden, thumbnailUrl }) {
    if (state.profileCatalog !== undefined) {
      state.profileCatalog.categoryList.forEach((category) => {
        const i = category.indicatorList.map(indicator => indicator.indicatorId).indexOf(indicatorId)
        if (i !== -1) {
          category.indicatorList[i].title = title
          category.indicatorList[i].shortDescription = shortDescription
          category.indicatorList[i].longDescription = longDescription
          category.indicatorList[i].dimensions = dimensions
          category.indicatorList[i].shortLicence = shortLicence
          category.indicatorList[i].shortSpdxLicence = shortSpdxLicence
          category.indicatorList[i].longLicence = longLicence
          category.indicatorList[i].version = version
          category.indicatorList[i].localUrl = localUrl
          category.indicatorList[i].publicUrl = publicUrl
          category.indicatorList[i].date = date
          category.indicatorList[i].gitHashKey = gitHashKey
          category.indicatorList[i].urlParams = urlParams
          category.indicatorList[i].urlParamsSchema = urlParamsSchema
          category.indicatorList[i].isHidden = isHidden
          category.indicatorList[i].thumbnailUrl = thumbnailUrl
        }
      })
    }
    if (state.userCatalog !== undefined) {
      state.userCatalog.categoryList.forEach((category) => {
        const i = category.indicatorList.map(indicator => indicator.indicatorId).indexOf(indicatorId)
        if (i !== -1) {
          category.indicatorList[i].title = title
          category.indicatorList[i].shortDescription = shortDescription
          category.indicatorList[i].longDescription = longDescription
          category.indicatorList[i].dimensions = dimensions
          category.indicatorList[i].shortLicence = shortLicence
          category.indicatorList[i].shortSpdxLicence = shortSpdxLicence
          category.indicatorList[i].longLicence = longLicence
          category.indicatorList[i].version = version
          category.indicatorList[i].localUrl = localUrl
          category.indicatorList[i].publicUrl = publicUrl
          category.indicatorList[i].date = date
          category.indicatorList[i].gitHashKey = gitHashKey
          category.indicatorList[i].urlParams = urlParams
          category.indicatorList[i].urlParamsSchema = urlParamsSchema
          category.indicatorList[i].isHidden = isHidden
          category.indicatorList[i].thumbnailUrl = thumbnailUrl
        }
      })
    }
  }
}

export const actions = {
  setNewIndicatorsAsRead ({ commit }) {
    catalogService.markAsReadNewProfileIndicators()
      .then(data => {
        if (data.success) {
          commit('removeNewIndicators')
        } else {
          throw new Error()
        }
      })
      .catch(err => {
        this.dispatch('popups/pushPopup', { message: 'ERROR' })
        console.error(err)
      })
  },
  markIndicatorHasRead ({ commit }, indicator) {
    catalogService.markAsReadNewProfileIndicator(indicator.indicatorId)
      .then(data => {
        if (data.success) {
          commit('removeNewIndicator', indicator)
        } else {
          throw new Error()
        }
      })
      .catch(err => {
        this.dispatch('popups/pushPopup', { message: 'ERROR' })
        console.error(err)
      })
  },
  addCategory ({ commit }, { catalogId, category }) {
    catalogService.addCategory(catalogId, category)
      .then(data => {
        if (data.success) {
          commit('addCategory', { catalogId, category: data.category })
        } else {
          throw new Error()
        }
      })
      .catch(err => {
        this.dispatch('popups/pushPopup', { message: 'ERROR' })
        console.error(err)
      })
  },
  addIndicatorInCategory ({ commit }, { catalogId, categoryId, position, isPublic, indicator }) {
    catalogService.addIndicatorInCategory(indicator.indicatorId, categoryId, position, isPublic)
      .then(data => {
        if (data.success) {
          const indicator = data.categoryIndicator
          commit('addIndicatorInCategory', { catalogId, categoryId, indicator })
          this.dispatch('popups/pushPopup', { message: i18n.global.t('SelectCategory.addIndicatorInCategory') })
          this.dispatch('catalog/getUnreadProfileIndicators')
        } else {
          if (data.error === 'DuplicateCategoryIndicatorException') {
            this.dispatch('popups/pushPopup', { message: i18n.global.t('SelectCategory.duplicateCategoryIndicatorException') })
          } else {
            throw new Error()
          }
        }
      })
      .catch(err => {
        this.dispatch('popups/pushPopup', { message: 'ERROR' })
        console.error(err)
      })
  },
  deleteCategory ({ commit }, { catalogId, categoryId }) {
    catalogService.deleteCategory(categoryId)
      .then(data => {
        if (data.success) {
          commit('deleteCategory', { catalogId, categoryId })
          this.dispatch('catalog/getUnreadProfileIndicators')
        } else {
          throw new Error()
        }
      })
      .catch(err => {
        this.dispatch('popups/pushPopup', { message: 'ERROR' })
        console.error(err)
      })
  },
  deleteIndicator ({ commit }, { catalogId, categoryId, indicatorId }) {
    catalogService.deleteIndicatorInCategory(indicatorId, categoryId)
      .then(data => {
        if (data.success) {
          commit('deleteIndicator', { catalogId, categoryId, indicatorId })
          this.dispatch('catalog/getUnreadProfileIndicators')
        } else {
          throw new Error()
        }
      })
      .catch(err => {
        this.dispatch('popups/pushPopup', { message: 'ERROR' })
        console.error(err)
      })
  },
  initProfileList ({ commit, dispatch }) {
    profileService.getProfileList()
      .then(data => {
        if (data.success) {
          commit('setProfileList', data.profileList)
          dispatch('selectProfile', store.getters['catalog/sortedProfileList'][0])
        } else {
          throw new Error()
        }
      })
      .catch(err => {
        this.dispatch('popups/pushPopup', { message: 'ERROR' })
        console.error(err)
      })
  },
  getUnreadProfileIndicators ({ commit, dispatch }) {
    catalogService.getUnreadProfileIndicators()
      .then(data => {
        if (data.success) {
          commit('setUnreadProfileIndicators', data.unreadProfileIndicatorList)
        } else {
          throw new Error()
        }
      })
      .catch(err => {
        this.dispatch('popups/pushPopup', { message: 'ERROR' })
        console.error(err)
      })
  },
  markAsReadNewProfileIndicators ({ dispatch }) {
    catalogService.markAsReadNewProfileIndicators()
      .then(data => {
        if (data.success) {
          // this.dispatch('popups/pushPopup', { message: 'new profiles indicators marked as read' })
        } else {
          throw new Error()
        }
      })
      .catch(err => {
        this.dispatch('popups/pushPopup', { message: 'ERROR' })
        console.error(err)
      })
  },
  initUserCatalog ({ commit }) {
    catalogService.getUserCatalog()
      .then(data => {
        if (data.success) {
          // Initial sort on position (categories in catalog and indicators in category)
          data.catalog.categoryList = data.catalog.categoryList.sort((a, b) => a.position - b.position)
          data.catalog.categoryList.forEach((category) => {
            category.indicatorList = category.indicatorList.sort((a, b) => a.position - b.position)
          })

          commit('setUserCatalog', data.catalog)
        } else {
          throw new Error()
        }
      })
      .catch(err => {
        this.dispatch('popups/pushPopup', { message: 'ERROR' })
        console.error(err)
      })
  },
  selectProfile ({ commit }, profile) {
    commit('selectProfile', profile)

    catalogService.getProfileCatalog(profile.id)
      .then(data => {
        if (data.success) {
          // Initial sort on position (categories in catalog and indicators in category)s
          data.catalog.categoryList = data.catalog.categoryList.sort((a, b) => a.position - b.position)
          data.catalog.categoryList.forEach((category) => {
            category.indicatorList = category.indicatorList.sort((a, b) => a.position - b.position)
          })

          commit('selectCatalog', data.catalog)
        } else {
          throw new Error()
        }
      })
      .catch(err => {
        this.dispatch('popups/pushPopup', { message: 'ERROR' })
        console.error(err)
      })
  },
  updateCategory ({ commit }, { catalogId, categoryId, title, position }) {
    catalogService.updateCategory(categoryId, title, position)
      .then(data => {
        if (data.success) {
          commit('updateCategory', { catalogId, categoryId, title, position })
        } else {
          throw new Error()
        }
      })
      .catch(err => {
        this.dispatch('popups/pushPopup', { message: 'ERROR' })
        console.error(err)
      })
  },
  updateIndicatorInCategory ({ commit }, { catalogId, categoryId, indicatorId, position, isPublic }) {
    catalogService.updateIndicatorInCategory(categoryId, indicatorId, position, isPublic)
      .then(data => {
        if (data.success) {
          commit('updateIndicatorInCategory', { catalogId, categoryId, indicatorId, position, isPublic })
        } else {
          throw new Error()
        }
      })
      .catch(err => {
        this.dispatch('popups/pushPopup', { message: 'ERROR' })
        console.error(err)
      })
  },
  deleteIndicatorFromLoadedCatalogs ({ commit }, indicatorId) { // To call when an indicator was removed from library
    commit('deleteIndicatorFromLoadedCatalogs', indicatorId)
  },
  updateIndicatorFromLoadedCatalogs ({ commit }, { indicatorId, title, url, isHidden, thumbnailUrl }) { // To call when an indicator was removed from library
    commit('updateIndicatorFromLoadedCatalogs', { indicatorId, title, url, isHidden, thumbnailUrl })
  }
}

export const getters = {
  sortedProfileList (state) {
    return (state.profileList === undefined) ? [] : sortWithString(state.profileList, false, 'title')
  }
}
