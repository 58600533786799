import NProgress from 'nprogress/nprogress'
import { createRouter, createWebHistory } from 'vue-router'

import store from '@store/index.js'

/* Useful if we have routes which are not visible anymore when authenticated
const ifNotAuthenticated = (to, from, next) => {
  if (!store.getters.isAuthenticated) {
    next()
    return
  }
  next('/')
}
*/

const ifAuthenticated = (to, from, next) => {
  if (store.getters['auth/isAuthenticated']) {
    next()
  } else if (store.getters['auth/isAuthenticated'] === undefined) {
    // When reloading the page the store is re-initialized -> manual check before routing
    store.dispatch('auth/checkSession')
      .then(() => {
        next()
      })
      .catch(err => {
        console.error('err', err)
        next('/')
      })
  } else {
    next('/')
  }
}

const getAuthenticatedStatus = (to, from, next) => {
  if (store.getters['auth/isAuthenticated']) {
    next()
  } else if (store.getters['auth/isAuthenticated'] === undefined) {
    // When reloading the page the store is re-initialized -> manual check before routing
    store.dispatch('auth/checkSession')
    next()
  } else {
    next()
  }
}

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  base: process.env.BASE_URL,
  linkExactActiveClass: 'router-link-exact-active theme-text-color',
  // Simulate native-like scroll behavior when navigating to a new
  // route and using back/forward buttons.
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@views/Home.vue')
    },
    {
      path: '/public',
      name: 'public',
      component: () => import('@views/PublicHome.vue')
    },
    {
      path: '/contact-us',
      name: 'contact-us',
      component: () => import('@views/ContactUs.vue')
    },
    {
      path: '/credits',
      name: 'credits',
      component: () => import('@views/Credits.vue')
    },
    {
      path: '/events',
      name: 'events',
      component: () => import('@views/Events.vue'),
      beforeEnter: getAuthenticatedStatus
    },
    {
      path: '/legal',
      name: 'legal',
      component: () => import('@views/Legal.vue')
    },
    {
      path: '/partners',
      name: 'partners',
      component: () => import('@views/Partners.vue')
    },
    {
      path: '/project',
      name: 'project',
      component: () => import('@views/Project.vue')
    },
    {
      path: '/sessionExpired',
      name: 'sessionExpired',
      component: () => import('@views/SessionExpired.vue')
    },
    {
      path: '/siteMap',
      name: 'siteMap',
      component: () => import('@views/SiteMap.vue')
    },
    {
      path: '/adminPanel',
      name: 'adminPanel',
      component: () => import('@views/AdministratorPanel.vue'),
      beforeEnter: ifAuthenticated
    },
    {
      path: '/dashboard',
      name: 'dashboard',
      component: () => import('@views/Dashboard.vue'),
      beforeEnter: ifAuthenticated
    },
    {
      path: '/portfolio/:folderId(\\d+)?',
      name: 'portfolio',
      component: () => import('@views/Portfolio.vue'),
      beforeEnter: ifAuthenticated
    },
    {
      path: '/messaging',
      name: 'messaging',
      component: () => import('@views/Messaging.vue'),
      beforeEnter: ifAuthenticated
    },
    {
      path: '/communities/:communityId(\\d+)?',
      name: 'communities',
      component: () => import('@views/Communities.vue'),
      beforeEnter: ifAuthenticated
    },
    {
      path: '/contacts/:listId(\\d+)?',
      name: 'contacts',
      component: () => import('@views/Contacts.vue'),
      beforeEnter: ifAuthenticated
    },
    {
      path: '/catalog',
      name: 'catalog',
      component: () => import('@views/UserCatalog.vue'),
      beforeEnter: ifAuthenticated
    },
    {
      path: '/catalog/indicator/:indicatorId',
      name: 'indicator',
      component: () => import('@views/UserCatalog.vue'),
      beforeEnter: ifAuthenticated
    },
    {
      path: '/catalogManager/indicator/:indicatorId',
      name: 'adminIndicator',
      component: () => import('@views/CatalogManager.vue'),
      beforeEnter: ifAuthenticated
    },
    {
      path: '/catalogmanager',
      name: 'catalogmanager',
      component: () => import('@views/CatalogManager.vue'),
      beforeEnter: ifAuthenticated
    },
    {
      path: '/newsmanager',
      name: 'newsmanager',
      component: () => import('@views/NewsManager.vue'),
      beforeEnter: ifAuthenticated
    },
    {
      path: '/account',
      name: 'account',
      component: () => import('@views/Account.vue'),
      beforeEnter: ifAuthenticated
    },
    {
      path: '/search/:query?',
      name: 'search',
      component: () => import('@views/Search.vue'),
      beforeEnter: ifAuthenticated
    },
    {
      path: '/cadyco-objects',
      name: 'cadycoObjects',
      component: () => import('@views/CadycoObjects'),
      beforeEnter: ifAuthenticated
    },
    {
      path: '/:pathMatch(.*)*',
      redirect: '/'
    }
  ]
})

// Update browser tab title
router.beforeEach((to, from, next) => {
  document.title = to.meta.title || 'CaDyCo'

  // If this isn't an initial page load...
  if (from.name !== null) {
    // Start the route progress bar.
    NProgress.start()
  }

  next()
})

router.afterEach((to, from) => {
  // Update active route in menu (CSS)
  // store.dispatch('updateActiveRoute', to.path)

  // Complete the animation of the route progress bar.
  NProgress.done()
})

export default router
