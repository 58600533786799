import axios from 'axios'
import constants from '@/constants/appConstants'
import { getCookie } from '@/utils/browser.util'

export default {
  addIndicator,
  deleteIndicator,
  getIndicatorList,
  updateIndicator,
  parseIndicatorJson,
  getPublicIndicators
}

const LIBRARY_PATH = '/indicator.indicator'

/**
 * Add indicator to catalog's category (fields: success, indicator)
 */
function addIndicator (title, shortDescription, longDescription, dimensions, shortLicence, shortSpdxLicence, longLicence, version, localUrl, publicUrl, date, gitHashKey, urlParams, urlParamsSchema, isHidden, imageBlob) {
  const formData = new FormData()
  formData.append('title', title)
  formData.append('shortDescription', shortDescription)
  formData.append('longDescription', longDescription)
  formData.append('dimensions', dimensions)
  formData.append('shortLicence', shortLicence)
  formData.append('shortSpdxLicence', shortSpdxLicence)
  formData.append('longLicence', longLicence)
  formData.append('version', version)
  formData.append('localUrl', localUrl)
  formData.append('publicUrl', publicUrl)
  formData.append('date', date)
  formData.append('gitHashKey', gitHashKey)
  formData.append('urlParams', urlParams)
  formData.append('urlParamsSchema', urlParamsSchema)
  formData.append('hidden', isHidden) // new indicators are hidden by default
  formData.append('picture', imageBlob, title + '-picture.png')
  formData.append('p_auth', getCookie('pauth'))

  return axios.post(constants.JSON_WS_URL + LIBRARY_PATH + '/add-indicator', formData)
    .then(response => response.data)
}

/**
 * Update indicator position and visibility (fields: success, indicator)
 */
function updateIndicator (indicatorId, title, shortDescription, longDescription, dimensions, shortLicence, shortSpdxLicence, longLicence, version, localUrl, publicUrl, date, gitHashKey, urlParams, urlParamsSchema, isHidden, imageBlob) {
  if (imageBlob === undefined) { // No need to update indicator Picture
    imageBlob = new File([], '')
  }

  const formData = new FormData()
  formData.append('indicatorId', indicatorId)
  formData.append('title', title)
  formData.append('shortDescription', shortDescription)
  formData.append('longDescription', longDescription)
  formData.append('dimensions', dimensions)
  formData.append('shortLicence', shortLicence)
  formData.append('shortSpdxLicence', shortSpdxLicence)
  formData.append('longLicence', longLicence)
  formData.append('version', version)
  formData.append('localUrl', localUrl)
  formData.append('publicUrl', publicUrl)
  formData.append('date', date)
  formData.append('gitHashKey', gitHashKey)
  formData.append('urlParams', urlParams)
  formData.append('urlParamsSchema', (urlParamsSchema && urlParamsSchema.length === 0) ? undefined : urlParamsSchema)
  formData.append('hidden', isHidden)
  formData.append('picture', imageBlob, title + '-picture.png') // Back-end actually doesn't care at all about fileName.. -_-
  formData.append('p_auth', getCookie('pauth'))

  return axios.post(constants.JSON_WS_URL + LIBRARY_PATH + '/update-indicator', formData)
    .then(response => response.data)
}

/**
 * Delete indicator from catalog's category (fields: success, indicator)
 */ // TODO test DELETE http method instead of POST
function deleteIndicator (indicatorId) {
  return axios.get(constants.JSON_WS_URL + LIBRARY_PATH + '/delete-indicator', {
    params: {
      p_auth: getCookie('pauth'),
      indicatorId: indicatorId
    }
  }).then(response => response.data)
}

/**
 * Get indicator list (fields: success, indicatorList)
 */
function getIndicatorList () {
  return axios.get(constants.JSON_WS_URL + LIBRARY_PATH + '/get-indicator-list', {
    params: {
      p_auth: getCookie('pauth')
    }
  }).then(response => response.data)
}

/**
 * Parse indicator json and return its content
 */
function parseIndicatorJson (jsonPath) {
  return axios.get(constants.JSON_WS_URL + LIBRARY_PATH + '/parse-indicator-json', {
    params: {
      p_auth: getCookie('pauth'),
      jsonPath: jsonPath
    }
  }).then(response => response.data)
}

/**
 * Get public indicators (fields: success, indicatorList)
 */
function getPublicIndicators () {
  return axios.get(constants.JSON_WS_URL + LIBRARY_PATH + '/get-public-indicators', {
    params: {
      p_auth: getCookie('pauth')
    }
  }).then(response => response.data)
}
