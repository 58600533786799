import axios from 'axios'
import constants from '@/constants/appConstants'
import { getCookie } from '@/utils/browser.util'

export {
  deleteHistory,
  deleteHistoryEntries,
  getSearchHistory,
  getSearchResults
}

const HISTORY_PATH = '/search.historyentry'
const ENGINE_PATH = '/search.searchengine'

/**
 * Delete full user history
 * @returns
 */
function deleteHistory () {
  return axios.get(constants.JSON_WS_URL + HISTORY_PATH + '/delete-history', {
    params: {
      p_auth: getCookie('pauth')
    }
  }).then(response => response.data)
}

/**
 * Delete search history entries from an id array
 * @param {*} historyEntryIds
 * @returns
 */
function deleteHistoryEntries (historyEntryIds) {
  return axios.get(constants.JSON_WS_URL + HISTORY_PATH + '/delete-history-entries', {
    params: {
      p_auth: getCookie('pauth'),
      historyEntryIds: JSON.stringify(historyEntryIds)
    }
  }).then(response => response.data)
}

/**
 * Get user search history
 */
function getSearchHistory () {
  return axios.get(constants.JSON_WS_URL + HISTORY_PATH + '/get-search-history', {
    params: {
      p_auth: getCookie('pauth')
    }
  }).then(response => response.data)
}

/**
 * Get search results
 */
function getSearchResults (keywords, params) {
  return axios.get(constants.JSON_WS_URL + ENGINE_PATH + '/search-keywords', {
    params: {
      p_auth: getCookie('pauth'),
      keywords,
      params: JSON.stringify(params)
    }
  }).then(response => response.data)
}
