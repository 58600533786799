<template>
  <div id="app">
    <RouterView />
  </div>
</template>

<script>
import { setMainColor } from '@utils/theme.util'

export default {
  created () {
    setMainColor(this.$store.state.theme.mainColor)
    // this.$store.dispatch('auth/checkSession')
  }
}
</script>

<style lang="scss">
// Allow element/type selectors, because this is global CSS.
// stylelint-disable selector-max-type, selector-class-pattern

// Normalize default styles across browsers,
// https://necolas.github.io/normalize.css/
@import '~normalize.css/normalize.css';
// Style loading bar between pages.
// https://github.com/rstacruz/nprogress
@import '~nprogress/nprogress.css';

// Pentila components library
@import 'src/design/cadyco-theme';

// Design variables and utilities from src/design.
@import '@design';

*,
*::before,
*::after {
  box-sizing: border-box;
}

html,
body,
#app {
  height: 100%;
  font-family: "Titillium Web", sans-serif;
}

body {
  background: $color-body-bg;
}
#app {
  @extend %typography-small;

  /* Datepicker background colors */
  --vdp-selected-bg-color: #0B3C5F;
  --vdp-hover-bg-color: #0B3C5F;
}

// ===
// Base element styles
// ===

a,
a:visited {
  color: $color-link-text;
}

// h1 {
//   @extend %typography-xxlarge;
// }

// h2 {
//   @extend %typography-xlarge;
// }

// h3 {
//   @extend %typography-large;
// }

// h4 {
//   @extend %typography-medium;
// }

// h5,
// h6 {
//   @extend %typography-small;
// }

ul {
  padding: 0;
  list-style-type: none;
}

li {
  display: inline-block;
  // margin: 0 10px;
}

@font-face {
  font-family: "Titillium Web";
  src: local("Titillium Web"),
   url(./assets/fonts/TitilliumWeb-Regular.ttf) format("truetype");
}

// ===
// Vendor
// ===

#nprogress .bar {
  color: $color-body-bg;
  background: $color-body-bg;
}
</style>
