<template>
  <li class="tag-item theme-background-color">
    <span class="label">{{ tag }}</span>
    <i
      class="pc-times"
      @click="onRemove"
    />
  </li>
</template>

<script>
export default {
  name: 'TagItem',
  props: {
    tag: {
      type: String,
      required: true
    }
  },
  emits: ['remove'],
  methods: {
    onRemove () {
      this.$emit('remove', this.tag)
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'src/design/common';

.tag-item {
  cursor: initial;
  margin: 2px;
  padding: 0 5px;
  display: inline-flex;
  font-size: 0.8rem;
  height: 26px;
  line-height: 26px;
  border-radius: 3px;
  align-items: center;
  max-width: 100%;

  @extend %no-text-highlight;

  .label {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    max-width: 95%;
  }

  i {
    margin-left: 5px;
    cursor: pointer;
    font-size: 0.6rem;
  }
}
</style>
