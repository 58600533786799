import { reactive } from 'vue'
import authService from '@/api/auth.service'
import userInfosService from '@/api/userInfos/userInfos.service'
import { JSON_WS_URL, PORTAL_CTX } from '@/constants/appConstants'

export const state = {
  status: undefined,
  user: undefined,
  loginURL: PORTAL_CTX + '/c/portal/login',
  logoutURL: PORTAL_CTX + '/c/portal/logout',
  // TODO find friendler url ?
  registerURL: PORTAL_CTX + '/home?p_p_id=com_liferay_login_web_portlet_LoginPortlet&p_p_lifecycle=0&p_p_state=maximized&p_p_mode=view&saveLastPath=false&_com_liferay_login_web_portlet_LoginPortlet_mvcRenderCommandName=%2Flogin%2Fcreate_account'
}

export const mutations = {
  authRequest (state) {
    state.status = 'loading'
  },
  authSuccess (state) {
    state.status = 'success'
  },
  authLogout (state) {
    state.status = ''
  },
  authError (state) {
    state.status = 'error'
  },
  setAuthenticatedUser (state, user) {
    state.user = reactive(user)
  },
  updateUser (state, { address, profile, picture }) {
    state.user.picture = picture
    state.user.address = address
    state.user.profile = profile
  }
}

export const actions = {
  checkSession ({ commit, state }) {
    // The Promise used for router redirect in login
    return new Promise((resolve, reject) => {
      // commit('authRequest')

      // Liferay login
      // authService.isSessionActive(state.loginURL)
      authService.isSessionActive(JSON_WS_URL + '/userinfos.userinfos/get-current-user')
        .then(data => {
          if (data.status === 200) {
            commit('authSuccess')
            resolve()
          } else {
            reject(new Error('No session found'))
          }
        })
        .catch(err => {
          commit('authError', err)
          reject(err)
        })
    })
  },
  logout ({ commit, state }) {
    commit('authLogout')
    authService.logout(state.logoutURL)
  },
  getUserInfos ({ commit }) {
    userInfosService.getUserInfos()
      .then(data => {
        commit('setAuthenticatedUser', data.user)
      })
  }
}

export const getters = {
  isAuthenticated: state => (state.status === undefined ? undefined : (state.status === 'success')),
  authStatus: state => state.status
}
