export const state = {
  fileInPost: undefined,
  indicatorInPost: undefined
}

export const mutations = {
  setFile (state, payload) {
    state.fileInPost = payload
  },
  removeFile (state) {
    state.fileInPost = undefined
  },
  setIndicator (state, payload) {
    state.indicatorInPost = payload
  },
  removeIndicator (state) {
    state.indicatorInPost = undefined
  }
}

export const actions = {
  setFile ({ commit }, file) {
    commit('setFile', file)
  },
  removeFile ({ commit }) {
    commit('removeFile')
  },
  setIndicator ({ commit }, indicator) {
    commit('setIndicator', indicator)
  },
  removeIndicator ({ commit }) {
    commit('removeIndicator')
  }
}
