<template>
  <div
    class="base-dropdown"
  >
    <button
      v-if="displayDropdown"
      class="button"
      type="button"
      :disabled="disabled"
      @click="toggleMenu"
      @keyup.up="onUpKey"
      @keyup.down="onDownKey"
      @keydown.enter="onEnter"
      @keyup.esc="onClose"
      @keydown.tab="onClose"
    >
      {{ getCurrentDisplayValue() }}
      <i class="pc-caret-down theme-text-color" />
    </button>
    <Autocomplete
      v-if="show"
      :list="sortedList"
      :display-field="displayField"
      :filtered="isFilterDisplayed"
      :highlighted-index="highlightedIndex"
      :style="'max-width:' + listMaxWidth + ';'"
      @select="onSelect"
      @close="onClose"
    />
  </div>
</template>

<script>
import { sortWithString } from '@utils/array.util'
import Autocomplete from '@/components/Base/Autocomplete'

export default {
  name: 'Dropdown',
  components: {
    Autocomplete
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    displayField: {
      type: String,
      default: undefined
    },
    filter: {
      type: Boolean,
      default: true
    },
    list: {
      type: Array,
      required: true
    },
    listMaxWidth: {
      type: String,
      default: 'none'
    },
    modelValue: {
      type: [String, Object],
      default: undefined
    },
    reversed: {
      type: Boolean,
      default: false
    },
    sort: {
      type: Boolean,
      default: true
    }
  },
  emits: ['update:modelValue'],
  data () {
    return {
      show: false,
      highlightedIndex: -1,
      isFilterDisplayed: (this.filter && this.list.length > 5)
    }
  },
  computed: {
    displayDropdown () {
      return (this.list.length > 0)
    },
    sortedList () {
      if (!this.sort) {
        return this.list
      }

      return sortWithString(this.list, this.reversed, this.displayField)
    }
  },
  created () {
    if (this.list.length > 0) {
      if (this.modelValue === undefined) {
        this.onSelect(this.sortedList[0])
      } else {
        var index = this.sortedList.indexOf(this.modelValue)
        this.onSelect(this.sortedList[index])
      }
    }
  },
  methods: {
    toggleMenu () {
      this.show = !this.show
    },
    getCurrentDisplayValue () {
      if (!this.modelValue) {
        return ''
      } else if (this.displayField === undefined) {
        return this.modelValue
      } else {
        return this.modelValue[this.displayField]
      }
    },
    onClose () {
      this.show = false
    },
    onDownKey () {
      this.highlightedIndex === (this.sortedList.length - 1)
        ? this.highlightedIndex = 0
        : ++this.highlightedIndex
    },
    onEnter ($e) {
      if (this.show && this.highlightedIndex > -1) {
        this.onSelect(this.sortedList[this.highlightedIndex])
        $e.preventDefault()
      }
    },
    onSelect (item) {
      if (item !== undefined && item !== this.modelValue) {
        this.$emit('update:modelValue', item)
      }
      this.onClose()
    },
    onUpKey () {
      this.highlightedIndex < 1
        ? this.highlightedIndex = (this.sortedList.length - 1)
        : --this.highlightedIndex
    }
  }
}
</script>

<style lang="scss" scoped>
.base-dropdown {
  display: inline-block;
  position: relative;
}
</style>
