import communities from '@/api/communities/communities.service'

export const state = {
  userCommunities: [],
  selectedCommunity: undefined
}

export const mutations = {
  updateUserCommunities (state, payload) {
    state.userCommunities = payload
  },
  updateSelectedCommunity (state, community) {
    state.selectedCommunity = community
  }
}

export const actions = {
  getUserCommunities ({ commit }, { idCommunityToSelect, mq, router }) {
    communities.getUserCommunities().then((data) => {
      if (data.success) {
        commit('updateUserCommunities', data.communityList)

        // Update selected community
        if (!idCommunityToSelect && data.communityList.length > 0) {
          if (!mq || (!mq.phone && !mq.tablet)) {
            commit('updateSelectedCommunity', data.communityList[0]) // If not specified, select the first community of the list (only on desktop, let it to undefined on phone)
            router.push({ name: 'communities', params: { communityId: data.communityList[0].id } }) // Update url to be consistent
          } else {
            commit('updateSelectedCommunity', undefined)
          }
        } else {
          const index = this.getters['communities/communityIndex'](idCommunityToSelect)
          commit('updateSelectedCommunity', data.communityList[index])
          router.push({ name: 'communities', params: { communityId: idCommunityToSelect } }) // Update url to be consistent
        }
      } else {
        console.error('Error while getting user\'s communities', data.error)
      }
    })
  },
  selectCommunity ({ commit }, community) {
    commit('updateSelectedCommunity', community)
  }
}

export const getters = {
  communityIndex: (state) => (communityId) => {
    return state.userCommunities.map(community => community.id).indexOf(communityId)
  },

  // Return the community in the list before the selected one
  previousCommunity (state) {
    if (state.selectedCommunity && state.userCommunities.length > 0) {
      const index = state.userCommunities.map(community => community.id).indexOf(state.selectedCommunity.id)
      if (index > 0) {
        return state.userCommunities[index - 1]
      } else if (index === 0 && state.userCommunities.length > 1) {
        return state.userCommunities[index + 1]
      } else {
        return undefined
      }
    } else {
      return undefined
    }
  }
}
