import moment from 'moment'
import i18n from '@/i18n'
import navigationService from '@/api/documents/navigation.service'
import activityService from '@/api/documents/activity.service'
import store from '@store'

// TODO explode file
const DriveUtils = {
  listFiles: [],

  addVersionIdToContent (content, openTabs) {
    for (let i = 0; i < content.length; ++i) {
      const component = content[i]
      if (component.type === 'component') { // only tabs have type 'component'
        // get fileVersion of component
        for (let j = 0; j < openTabs.length; ++j) {
          if (openTabs[j].id === component.id) {
            component.versionId = openTabs[j].versionId
          }
        }
      } else { // may be a stack, a row or others...
        DriveUtils.addVersionIdToContent(component.content, openTabs)
      }
    }
  },

  isInList (entityId, listEntities) {
    for (let i = 0; i < listEntities.length; ++i) {
      if (listEntities[i].id === entityId) {
        return true
      }
    }
    return false
  },

  getIndex (listEntities, entityId) {
    for (let i = 0; i < listEntities.length; ++i) {
      if (listEntities[i].id === entityId) {
        return i
      }
    }
    return -1
  },

  removeEntitiesFromArray (listEntities, listEntitiesToRemove) {
    if (listEntitiesToRemove.length <= listEntities.length) {
      for (let i = 0; i < listEntitiesToRemove.length; ++i) {
        let find = false
        for (let j = 0; j < listEntities.length; ++j) {
          if (listEntitiesToRemove[i].id === listEntities[j].id) {
            find = true
            listEntities.splice(j, 1)
            break
          }
        }
        if (find === false) {
          console.error('Unable to find entity', listEntitiesToRemove[i], 'in list', listEntities)
        }
      }
    }
  },

  findEntitiesFromArray (listEntities, listIdEntitiesToFind) {
    const listEntitiesFound = []
    for (let i = 0; i < listIdEntitiesToFind.length; ++i) {
      for (let j = 0; j < listEntities.length; ++j) {
        if (listIdEntitiesToFind[i].id === listEntities[j].id) {
          listEntitiesFound.push(listEntities[j])
          break
        }
      }
    }
    return listEntitiesFound
  },

  belongsToEntities (entityToTest, listEntities) {
    for (let i = 0; i < listEntities.length; ++i) {
      if (entityToTest.id === listEntities[i].id) {
        return true
      }
    }
    return false
  },

  previousFile (allSortedDocuments, selectedFiles) {
    if (allSortedDocuments.length - selectedFiles.length === 0) {
      return undefined
    } else {
      const firstFileOfSelection = selectedFiles[0]
      let indexOfFirstFileOfSelection
      for (let i = 0; i < allSortedDocuments.length; ++i) {
        if (allSortedDocuments[i].id === firstFileOfSelection.id) {
          indexOfFirstFileOfSelection = i
          break
        }
      }
      if (indexOfFirstFileOfSelection === 0) {
        return allSortedDocuments[selectedFiles.length]
      } else {
        return allSortedDocuments[indexOfFirstFileOfSelection - 1]
      }
    }
  },

  compare (sortType, isOrderAsc) {
    return function (a, b) {
      if (sortType === 'name') {
        if (isOrderAsc) {
          return (DriveUtils.nameWithoutExtension(a[sortType])).localeCompare(DriveUtils.nameWithoutExtension(b[sortType])) // lexicographical sort (string sort)
        } else {
          return (DriveUtils.nameWithoutExtension(b[sortType])).localeCompare(DriveUtils.nameWithoutExtension(a[sortType]))
        }
      } else if (sortType === 'size') {
        if (isOrderAsc) {
          return a[sortType] - b[sortType]
        } else {
          return b[sortType] - a[sortType]
        }
      } else if (sortType === 'lastModifiedDate' ||
        sortType === 'creationDate' ||
        sortType === 'date' ||
        sortType === 'deleteDate') {
        // return (a[sortType]).localeCompare(b[sortType]) // works because date are at format YYYY-MM-DD HH:mm:ss
        const dateA = moment(a[sortType], 'YYYY-MM-DD HH:mm:ss') // note that lastModifiedDate might be is sending date
        const dateB = moment(b[sortType], 'YYYY-MM-DD HH:mm:ss')
        if (isOrderAsc) {
          return dateA.diff(dateB)
        } else {
          return dateB.diff(dateA)
        }
      } else {
        console.error('error: trying to compare ', a[sortType], ' and ', b[sortType],
          'with type ', sortType)
      }
    }
  },

  nameWithoutExtension (name) {
    if (name.split('.').length === 1) {
      return name
    }
    return name.split('.').slice(0, -1).join('.')
  },

  downLoadDocument (entity) {
    return new Promise((resolve) => {
      if (entity.type === 'Folder') {
        store.dispatch('currentActions/addAction', { name: 'download' })
        navigationService.downloadFolder(entity.id).then((data) => {
          store.dispatch('currentActions/removeAction', { name: 'download' })
          if (data.success) {
            const url = data.downloadURL

            const a = document.createElement('a')
            a.style.display = 'none'
            a.download = entity.name // don't works on Internet Explorer and IOS' safari
            a.href = url
            a.click()
            resolve()
          } else {
            console.error('Error in getting url for folder archive download')
          }
        })
      } else if (entity.type === 'File') {
        const a = document.createElement('a')
        a.style.display = 'none'
        a.download = entity.name // don't works on Internet Explorer and IOS' safari
        a.href = entity.url
        a.click()

        activityService.recordDownloadActivity(entity.id, 0)
        resolve()
      }
    })
  },

  selectBetween (listSortedFiles, firstFile, secondFile) {
    let idxLastSelectedFile = -1
    let idxFile = -1
    for (let i = 0; i < listSortedFiles.length; ++i) {
      if (listSortedFiles[i].id === firstFile.id) { idxLastSelectedFile = i }
      if (listSortedFiles[i].id === secondFile.id) { idxFile = i }
    }
    if (idxLastSelectedFile === -1 || idxFile === -1) {
      console.error('error when trying to get files between ' + firstFile.name + ' and ' + secondFile.name)
      return []
    } else {
      if (idxLastSelectedFile < idxFile) {
        return listSortedFiles.slice(idxLastSelectedFile, idxFile + 1)
      } else {
        return listSortedFiles.slice(idxFile, idxLastSelectedFile + 1)
      }
    }
  },

  selectDocument (store, allEntities, selectedEntities, event) {
    if (allEntities.length !== 0) {
      if (selectedEntities.length === 0 || !DriveUtils.belongsToEntities(selectedEntities[0], allEntities)) { // because the currentFolder can be the selected entities
        // No files selected, select first file of allEntities
        store.dispatch('files/selectOneFile', allEntities[0])
      } else if (selectedEntities.length === 1) {
        for (let i = 0; i < allEntities.length; ++i) {
          if (allEntities[i].id === selectedEntities[0].id) {
            if (event.key === 'ArrowDown') {
              store.dispatch('files/selectOneFile', allEntities[(i + 1) % allEntities.length])
            } else if (event.key === 'ArrowUp') {
              store.dispatch('files/selectOneFile', allEntities[(i + allEntities.length - 1) % allEntities.length])
            }
            break
          }
        }
      }
    }
  },

  selectCtrlDocument (store, allEntities, lastSelectedEntity, event) {
    if (allEntities.length !== 0) {
      if (lastSelectedEntity.id === undefined) {
        store.dispatch('files/selectOneFile', allEntities[0])
      } else {
        for (let i = 0; i < allEntities.length; ++i) {
          if (allEntities[i].id === lastSelectedEntity.id) {
            if (event.key === 'ArrowDown') {
              store.dispatch('files/updateCtrlSelectedFiles', allEntities[(i + 1) % allEntities.length])
            } else if (event.key === 'ArrowUp') {
              store.dispatch('files/updateCtrlSelectedFiles', allEntities[(i + allEntities.length - 1) % allEntities.length])
            }
            break
          }
        }
      }
    }
  },

  formatOptions (fields) {
    const contextMenuOptions = []
    let i
    for (i = 0; i < fields.length; ++i) {
      const option = {
        name: fields[i].name,
        title: fields[i].label,
        isGrayed: fields[i].isPermanent,
        position: i,
        hasCheckbox: true,
        isSelected: fields[i].isSelected,
        hasSeparator: (i === fields.length - 1)
      }
      contextMenuOptions.push(option)
    }
    contextMenuOptions.push({
      name: 'resetFields',
      title: i18n.global.t('ContextMenuOptions.resetFields'),
      position: i,
      hasSeparator: false
    })
    return contextMenuOptions
  },

  formatSize (size) {
    if (Math.trunc(size / 1024) === 0) {
      return size + ' o'
    } else if ((Math.trunc(size / (1024 * 1024)) === 0)) {
      return Math.trunc(size / 1024) + ' ko'
    } else if ((Math.trunc(size / (1024 * 1024 * 1024)) === 0)) {
      return Math.trunc(size / (1024 * 1024)) + ' Mo'
    } else {
      return Math.trunc(size / (1024 * 1024 * 1024)) + ' Go'
    }
  },

  findMenuOption (menuOptions, name) {
    for (let i = 0; i < menuOptions.length; ++i) {
      if (menuOptions[i].name === name) {
        return true
      }
    }
    return false
  },

  removeMenuOptionIfExist (menuOptions, name) {
    let indexOption = -1
    for (let i = 0; i < menuOptions.length; ++i) {
      if (menuOptions[i].name === name) {
        indexOption = i
        break
      }
    }
    if (indexOption !== -1) {
      // Delete the option
      menuOptions.splice(indexOption, 1)
      // Reindex the position property
      for (let i = indexOption; i < menuOptions.length; ++i) {
        menuOptions[i].position -= 1
      }
    }
  },

  // compute the union of each context menu option
  mergeContextMenus (listContextMenu) {
    if (listContextMenu.length === 0) return []
    const CMResult = listContextMenu[0]
    for (let i = 1; i < listContextMenu.length; ++i) {
      for (let j = 0; j < CMResult.length; ++j) {
        let find = false
        for (let k = 0; k < listContextMenu[i].length; ++k) {
          if (CMResult[j].name === listContextMenu[i][k].name) {
            find = true
          }
        }
        if (!find) {
          CMResult.splice(j, 1)
          j--
        }
      }
    }
    return CMResult
  },

  // Return a new array with copies of objects of the original one
  cloneArrayOfObject (array) {
    const newArray = []
    for (let i = 0; i < array.length; ++i) {
      newArray.push(Object.create(array[i])) // Create a copy of the object, not a reference
    }
    return newArray
  },

  // Browse directory recursively and update this.listFiles
  // Send a promise when all the files were added
  addFilesFromDirectory (directory, path) {
    return new Promise((resolve) => {
      const ignoreHiddenFiles = true
      const dirReader = directory.createReader()

      const entriesReader = (function (_this) { // declare entriesReader function to get content of folder
        return function (entries) {
          let entry
          const listFilesPromises = []
          const listDirectoryPromises = []
          for (let i = 0; i < entries.length; ++i) { // for each entry in current folder
            entry = entries[i]
            if (entry.isFile) { // if it's a file
              const filePromise = new Promise((resolve) => { // return a promise when the file were added to this.listFiles
                entry.file(function (file) {
                  if (ignoreHiddenFiles && entry.name.substring(0, 1) === '.') {
                    return
                  }
                  // Declare new file to be able to change name
                  const newFile = new File([file], path + '/' + file.name, { type: file.type })
                  _this.listFiles.push(newFile)
                  resolve()
                })
              })
              listFilesPromises.push(filePromise)
            } else if (entry.isDirectory) { // if it's a folder
              const directoryPromise = new Promise((resolve) => { // return a promise when the recursive call do it job (add all sub files to this.listFiles)
                DriveUtils.addFilesFromDirectory(entry, path + '/' + entry.name).then(() => {
                  // Nothing to do here
                  resolve()
                })
              })
              listDirectoryPromises.push(directoryPromise)
            }
          }
          // when all the declared promises were handle, we can resolve the main promise that mean the job is done
          Promise.all([...listFilesPromises, ...listDirectoryPromises]).then(() => {
            resolve()
          })
        }
      })(this)
      // /!\ readEntries fct is a web 'non standard' and 'experimental technology'
      // it may not work for every user
      dirReader.readEntries(entriesReader, function (error) { console.error(error) })
    })
  },

  returnAddedFiles (e, store, allowMultiple = true, requiresTypeCheck = false, acceptedTypes = []) {
    return new Promise((resolve) => {
      this.listFiles = []
      const wasDropped = e.dataTransfer
      let entry
      if (wasDropped) {
        const items = e.dataTransfer.items
        for (let i = 0; i < items.length; ++i) {
          const item = items[i]
          // If is a folder
          if ((item.webkitGetAsEntry != null) && (entry = item.webkitGetAsEntry()) && entry.isDirectory) {
            DriveUtils.addFilesFromDirectory(entry, entry.name).then(() => {
              this.checkFilesType(allowMultiple, requiresTypeCheck, acceptedTypes)
              DriveUtils.checkFilesSize(this.listFiles, store)
              resolve(this.listFiles)
            })
          } else {
            if (item.getAsFile != null) {
              if ((item.kind == null) || item.kind === 'file') {
                this.listFiles.push(item.getAsFile())
              } else {
                this.listFiles.push(undefined)
              }
            } else {
              this.listFiles.push(undefined)
            }
            this.checkFilesType(allowMultiple, requiresTypeCheck, acceptedTypes)
            DriveUtils.checkFilesSize(this.listFiles, store)
            resolve(this.listFiles)
          }
        }
      } else {
        const filesObj = e.target.files
        for (let i = 0; i < filesObj.length; ++i) { // Necessary to have a array of object
          this.listFiles.push(filesObj[i])
        }
        this.checkFilesType(allowMultiple, requiresTypeCheck, acceptedTypes)
        DriveUtils.checkFilesSize(this.listFiles, store)
        resolve(this.listFiles)
      }
    })
  },

  checkFilesType (allowMultiple, requiresTypeCheck, acceptedTypes) {
    if (!allowMultiple && this.listFiles.length > 1) {
      console.error('vue-file-picker: Multiple Files are not allowed')
    }
    if (requiresTypeCheck) {
      for (let i = 0; i < this.listFiles.length; i++) {
        if (acceptedTypes.indexOf(this.listFiles[i].type) === -1) {
          console.error('vue-file-picker: File type ' + this.listFiles[i].type + ' not allowed')
        }
      }
    }
  },

  checkFilesSize (listFiles, store) {
    const invalidFilesList = []

    if (invalidFilesList.length !== 0) {
      store.dispatch('error/setErrorType', 'reachMaxSize')
      store.dispatch('error/setListFilesConcerns', invalidFilesList)
      store.dispatch('monDrive/openErrorModal')
    }
  },

  alertNoFile () {
    alert(i18n.global.t('Alerts.errorNoFiles'))
  }
}

export default DriveUtils
