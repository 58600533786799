import { sortWithString } from '@/utils/array.util'
import eventService from '@/api/news/event.service'

export const state = {
  eventList: undefined
}

export const mutations = {
  addEvent (state, event) {
    state.eventList.push(event)
  },
  deleteEvent (state, eventId) {
    const i = state.eventList.map(item => item.eventId).indexOf(eventId)
    state.eventList.splice(i, 1)
  },
  setEventList (state, eventList) {
    state.eventList = eventList
  },
  updateEvent (state, event) {
    const i = state.eventList.map(item => item.eventId).indexOf(event.eventId)
    state.eventList[i].title = event.title
    state.eventList[i].releaseDate = event.releaseDate
    state.eventList[i].content = event.content
    state.eventList[i].eventDate = event.eventDate
    state.eventList[i].location = event.location
    state.eventList[i].linkDescription = event.linkDescription
    state.eventList[i].linkUrl = event.linkUrl
    state.eventList[i].thumbnail = event.thumbnail
  }
}

export const actions = {
  addEvent ({ commit }, event) {
    eventService.createEvent(event)
      .then(data => {
        if (data.success) {
          commit('addEvent', data.createdEvent)
        } else {
          throw new Error()
        }
      })
      .catch(err => {
        this.dispatch('popups/pushPopup', { message: 'ERROR' })
        console.error(err)
      })
  },
  deleteEvent ({ commit }, eventId) {
    eventService.deleteEvent(eventId)
      .then(data => {
        if (data.success) {
          commit('deleteEvent', eventId)
        } else {
          throw new Error()
        }
      })
      .catch(err => {
        this.dispatch('popups/pushPopup', { message: 'ERROR' })
        console.error(err)
      })
  },
  initEventList ({ commit }) {
    eventService.getEventList()
      .then(data => {
        if (data.success) {
          commit('setEventList', data.eventList)
        } else {
          throw new Error()
        }
      })
      .catch(err => {
        this.dispatch('popups/pushPopup', { message: 'ERROR' })
        console.error(err)
      })
  },
  updateEvent ({ commit }, event) {
    eventService.editEvent(event)
      .then(data => {
        if (data.success) {
          commit('updateEvent', data.editedEvent)
        } else {
          throw new Error()
        }
      })
      .catch(err => {
        this.dispatch('popups/pushPopup', { message: 'ERROR' })
        console.error(err)
      })
  }
}

export const getters = {
  sortedEventList (state) {
    return (state.eventList === undefined) ? undefined : sortWithString(state.eventList, false, 'modifiedDate')
  }
}
