import { normalize } from '@utils/formatters.util'

export {
  filter,
  sortWithString
}

// Filter array with string
function filter (filter, array, property) {
  filter = normalize(filter)

  return array.filter((item) => {
    if (filter.length === 0) {
      return true
    }

    var value = (property !== undefined) ? item[property] : item
    return normalize(value).includes(filter)
  })
}

function sortWithString (array, reversed = false, property) {
  // sort array based on alphabetic order of property
  var sortedArray = array.slice()

  function compare (a, b) {
    if (property !== undefined) {
      a = a[property]
      b = b[property]
    }
    a = normalize(a)
    b = normalize(b)

    if (reversed) {
      if (a < b) return 1
      if (a > b) return -1
    } else {
      if (a < b) return -1
      if (a > b) return 1
    }
    return 0
  }

  return sortedArray.sort(compare)
}
