<template>
  <div class="component">
    <div
      :class="{'theme-border-color': isInputFocused, 'disabled': disabled}"
      class="base-tags-input"
      @click="setFocus"
    >
      <ul class="tag-list">
        <TagItem
          v-for="(tag, index) in modelValue"
          :key="index"
          :tag="getDisplayValue(tag)"
          @remove="removeTag"
        />
      </ul>
      <Input
        v-if="!reachMaxLength"
        ref="input"
        v-model="inputValue"
        :placeholder="placeholder"
        :disabled="disabled || reachMaxLength"
        @keyup.enter="onEnter"
        @keydown.delete="onDelete"
        @keyup.up="onUpKey"
        @keyup.down="onDownKey"
        @keyup.esc="onEscape"
        @keydown.tab="onEscape"
        @focus="onFocus"
        @input="onInput"
      />
    </div>
    <Autocomplete
      v-if="displayCompletion"
      :list="filteredList"
      :display-field="displayField"
      :highlighted-index="highlightedIndex"
      @select="addTag"
      @close="onClose"
    />
  </div>
</template>

<script>
import { sortWithString, filter } from '@utils/array.util'

import Autocomplete from '@/components/Base/Autocomplete'
import Input from '@/components/Base/Input'
import TagItem from '@/components/Base/TagItem'

export default {
  name: 'TagsInput',
  components: {
    Autocomplete,
    Input,
    TagItem
  },
  props: {
    closeOnSelect: { type: Boolean, default: false },
    completionOnly: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    displayField: { type: String, default: undefined },
    idField: { type: String, default: undefined },
    list: { type: Array, default: () => [] },
    minLength: { type: Number, default: 0 },
    maxSize: { type: Number, default: -1 },
    modelValue: { type: Array, default: () => [] },
    placeholder: { type: String, default: '' },
    sort: { type: Boolean, default: true }
  },
  emits: ['blur', 'update:modelValue', 'inputChange'],
  data () {
    return {
      highlightedIndex: -1,
      isInputFocused: false,
      inputValue: ''
    }
  },
  computed: {
    displayCompletion () {
      return (this.inputValue.length >= this.minLength && this.isInputFocused) && !this.reachMaxLength
    },
    reachMaxLength () {
      return (this.maxSize !== -1 && this.modelValue.length >= this.maxSize)
    },
    sortedList () {
      if (!this.sort) {
        return this.list
      }
      var reverse = false
      return sortWithString(this.list, reverse, this.displayField)
    },
    filteredList () {
      var modelValue = this.modelValue
      var vm = this

      if (this.displayField !== undefined) {
        modelValue = []
        for (var idx = 0; idx < this.modelValue.length; ++idx) {
          modelValue.push(this.getIdValue(this.modelValue[idx]))
        }
      }

      return filter(this.inputValue,
        this.sortedList.filter(function (item) {
          return !modelValue.includes(vm.getIdValue(item))
        }),
        this.displayField)
    }
  },
  watch: {
    'inputValue' () {
      this.$emit('inputChange', this.inputValue)
    }
  },
  mounted () {
    if (this.completionOnly) {
      this.highlightedIndex = 0
    }
  },
  methods: {
    addTag (tag) {
      var tags = this.modelValue.slice()
      tags.push(tag)
      this.$emit('update:modelValue', tags)
      this.inputValue = ''
      this.highlightedIndex = -1
      if (this.closeOnSelect) {
        this.hideCompletion()
      } else {
        this.setFocus()
      }
    },
    getDisplayValue (tag) {
      if (this.displayField === undefined) {
        return tag
      } else {
        return tag[this.displayField]
      }
    },
    getIdValue (tag) {
      // Return id if defined else return text modelValue
      if (this.idField !== undefined) {
        return tag[this.idField]
      }
      return this.getDisplayValue(tag)
    },
    hideCompletion () {
      this.isInputFocused = false
      this.$emit('blur')
    },
    onClose () {
      if (this.$refs.input.$el !== document.activeElement) {
        this.hideCompletion()
      }
    },
    onDelete () {
      if (this.inputValue.length === 0 && this.modelValue.length > 0) {
        var tags = this.modelValue.slice()
        tags.pop()
        this.$emit('update:modelValue', tags)
      }
    },
    onDownKey () {
      const minValue = this.completionOnly ? 0 : -1
      this.highlightedIndex === (this.filteredList.length - 1)
        ? this.highlightedIndex = minValue
        : ++this.highlightedIndex
    },
    onEnter () {
      if (this.displayCompletion && this.highlightedIndex !== -1) {
        if (this.filteredList.length > 0) {
          this.addTag(this.filteredList[this.highlightedIndex])
        }
      } else if (!this.completionOnly && this.inputValue !== '') {
        let tag
        if (this.displayField === undefined) {
          tag = this.inputValue
        } else {
          tag = {}
          tag[this.displayField] = this.inputValue
        }
        this.addTag(tag)
      }
    },
    onEscape () {
      this.hideCompletion()
    },
    onFocus () {
      this.isInputFocused = true
    },
    onInput () {
      this.onFocus()
      this.highlightedIndex = this.completionOnly ? 0 : -1
    },
    onUpKey () {
      const minValue = this.completionOnly ? 0 : -1
      this.highlightedIndex <= minValue
        ? this.highlightedIndex = (this.filteredList.length - 1)
        : --this.highlightedIndex
    },
    setFocus () {
      this.$nextTick(() => { if (this.$refs.input) this.$refs.input.$el.focus() })
    },
    removeTag (tagLabel) {
      var vm = this
      var tags = this.modelValue.slice().filter(function (item) {
        return (vm.getDisplayValue(item) !== tagLabel)
      })
      this.$emit('update:modelValue', tags)
    }
  }
}
</script>

<style lang="scss" scoped>
.component {
  position: relative;
}

.base-tags-input {
  padding: 4px 2px;
  cursor: text;

  &.disabled {
    cursor: not-allowed;

    .tag-list {
      pointer-events: none;
    }
  }
}

.tag-list {
  margin: 0;
  padding: 0;
  display: inline-block;
  list-style-type: none;
  vertical-align: top;
  max-width: 100%;
}

.base-input {
  border-bottom: 0;
  margin: 2px;
  padding: 0 0 0 5px;
  height: 26px;
  width: auto;
}
</style>
