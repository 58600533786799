import i18n from '@/i18n'
import moment from 'moment'

export {
  formatName,
  formatType,
  formatSize,
  formatDate,
  normalize
}

export default {
  formatName,
  formatType,
  formatSize,
  formatDate
}

function formatName (name, haveExtension) {
  if (haveExtension) {
    // remove extension from name
    let i = name.length - 1
    while (name[i] !== '.') {
      name = name.slice(0, -1)
      i--
    }
    name = name.slice(0, -1)
    return name
  } else {
    return name
  }
}

function formatType (type, extension) {
  if (type === 'Folder') {
    return i18n.global.t('AppCommonsLabels.Documents.folder')
  } else if (type === 'File') {
    return i18n.global.t('AppCommonsLabels.Documents.file') + ' ' + extension
  } else {
    console.error('Unknown document type')
    return 'Unknown type'
  }
}

function formatSize (size) {
  if (Math.trunc(size / 1024) === 0) {
    return size + ' o'
  } else if ((Math.trunc(size / (1024 * 1024)) === 0)) {
    return Math.trunc(size / 1024) + ' ko'
  } else if ((Math.trunc(size / (1024 * 1024 * 1024)) === 0)) {
    return Math.trunc(size / (1024 * 1024)) + ' Mo'
  } else {
    return Math.trunc(size / (1024 * 1024 * 1024)) + ' Go'
  }
}

function formatDate (stringDate) {
  return moment(stringDate, 'YYYY-MM-DD HH:mm:ss').calendar()
}

// Strip string from accented characters, uppercase and useless whitespaces
function normalize (str) {
  return str.normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase()
    .trim()
}
