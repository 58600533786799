export const state = {
  imagePickerModal: {
    dimensions: undefined,
    initializerPictureURL: undefined,
    isDisplayed: false,
    onConfirm: Function
  },
  renameModal: {
    entityToRename: undefined,
    isDisplayed: false
  },
  isCreateFolderModalDisplayed: false,
  isCreatePostModalDisplayed: false,
  isFormationDetailsModalDisplayed: false,
  isEtablissementDetailsModalDisplayed: false,
  formationDetails: undefined,
  etablissementDetails: undefined,
  composanteToDisplay: undefined
}

export const mutations = {
  updateImagePickerModalState (state, payload) {
    state.imagePickerModal.onConfirm = payload.onConfirm
    state.imagePickerModal.isDisplayed = payload.show
    state.imagePickerModal.initializerPictureURL = payload.initializerPictureURL
    state.imagePickerModal.dimensions = payload.dimensions
  },
  setCreateFolderModalDisplayed (state, payload) {
    state.isCreateFolderModalDisplayed = payload
  },
  setCreatePostModalDisplayed (state, payload) {
    state.isCreatePostModalDisplayed = payload
  },
  setRenameModalDisplayed (state, payload) {
    state.renameModal.isDisplayed = payload
  },
  setRenameModalEntity (state, payload) {
    state.renameModal.entityToRename = payload
  },
  setFormationDetailsModalDisplayed (state, payload) {
    state.isFormationDetailsModalDisplayed = payload
  },
  setEtablissementDetailsModalDisplayed (state, payload) {
    state.isEtablissementDetailsModalDisplayed = payload
  },
  setEtablissementDetails (state, payload) {
    state.etablissementDetails = payload
  },
  setComposanteToDisplay (state, payload) {
    state.composanteToDisplay = payload
  },
  setFormationDetails (state, payload) {
    state.formationDetails = payload
  }
}

export const actions = {
  closeImagePickerModal ({ commit }) {
    commit('updateImagePickerModalState', { show: false, initializerPictureURL: undefined })
  },
  closeCreateFolderModal ({ commit }) {
    commit('setCreateFolderModalDisplayed', false)
  },
  closeCreatePostModal ({ commit }) {
    commit('setCreatePostModalDisplayed', false)
  },
  closeRenameModal ({ commit }) {
    commit('setRenameModalDisplayed', false)
    commit('setRenameModalEntity', undefined)
  },
  closeFormationDetailsModal ({ commit }) {
    commit('setFormationDetailsModalDisplayed', false)
  },
  closeEtablissementDetailsModal ({ commit }) {
    commit('setEtablissementDetailsModalDisplayed', false)
    commit('setEtablissementDetails', undefined)
    commit('setComposanteToDisplay', undefined)
  },
  openImagePickerModal ({ commit }, payload) {
    commit('updateImagePickerModalState', { show: true, ...payload })
  },
  openCreateFolderModal ({ commit }) {
    commit('setCreateFolderModalDisplayed', true)
  },
  openCreatePostModal ({ commit }) {
    commit('setCreatePostModalDisplayed', true)
  },
  openRenameModal ({ commit }, entityToRename) {
    commit('setRenameModalDisplayed', true)
    commit('setRenameModalEntity', entityToRename)
  },
  openFormationDetailsModal ({ commit }, formation) {
    commit('setFormationDetails', formation)
    commit('setFormationDetailsModalDisplayed', true)
  },
  openEtablissementDetailsModal ({ commit }, { etablissement, composante }) {
    commit('setEtablissementDetails', etablissement)
    commit('setComposanteToDisplay', composante)
    commit('setEtablissementDetailsModalDisplayed', true)
  }
}
