import i18n from '@/i18n'
import dayjs from 'dayjs'
import { sortWithString } from '@utils/array.util'
import { deleteHistory, deleteHistoryEntries, getSearchHistory, getSearchResults } from '@/api/search.service'

export const state = {
  historyList: undefined,
  isLocked: false,
  query: '',
  resultCount: 0,
  resultList: undefined,
  resultsPerPage: 20,
  searchDuration: 0
}

export const mutations = {
  deleteHistoryEntries (state, payload) {
    payload.forEach(id => {
      const index = state.historyList.map(item => item.searchId).indexOf(id)
      state.historyList.splice(index, 1)
    })
  },
  emptyHistoryList (state) {
    state.historyList.length = 0
  },
  setLock (state, payload) {
    state.isLocked = payload
  },
  setHistoryList (state, payload) {
    state.historyList = payload
  },
  setQuery (state, payload) {
    state.query = payload
  },
  setResultCount (state, payload) {
    state.resultCount = payload
  },
  setResultList (state, payload) {
    if (state.resultList && payload) {
      state.resultList = state.resultList.concat(payload)
    } else {
      state.resultList = payload
    }
    // Set lock if we hit last page
    state.isLocked = payload !== undefined && payload.length < state.resultsPerPage
  },
  setSearchDuration (state, payload) {
    payload = payload.replace(/,/g, '.') // replace ',' character by . for js Number compatibility
    state.searchDuration = payload
  }
}

export const actions = {
  deleteHistory ({ commit }) {
    deleteHistory().then(data => {
      if (data.success) {
        commit('emptyHistoryList')
      } else {
        throw new Error()
      }
    })
      .catch(err => {
        this.dispatch('popups/pushPopup', { message: i18n.global.t('Search.deleteErrorMessage') })
        console.error(err)
      })
  },
  deleteSearchEntries ({ commit }, searchEntryIds) {
    deleteHistoryEntries(searchEntryIds).then(data => {
      if (data.success) {
        commit('deleteHistoryEntries', searchEntryIds)
      } else {
        throw new Error()
      }
    })
      .catch(err => {
        this.dispatch('popups/pushPopup', { message: i18n.global.t('Search.deleteErrorMessage') })
        console.error(err)
      })
  },
  initSearchHistory ({ commit }) {
    getSearchHistory()
      .then(data => {
        if (data.success) {
          commit('setHistoryList', data.searchHistory)
        } else {
          throw new Error()
        }
      })
      .catch(err => {
        this.dispatch('popups/pushPopup', { message: i18n.global.t('Search.getHistoryErrorMessage') })
        console.error(err)
      })
  },
  searchQuery ({ commit, state }, { params, query }) {
    if (!query) {
      return
    }

    if (query !== state.query) {
      commit('setQuery', query)
    }

    commit('setLock', true)
    getSearchResults(state.query, params).then(data => {
      if (data.success) {
        if (params.pageNb === 0) {
          commit('setResultList', undefined)
          commit('setResultCount', data.resultCount)
          commit('setSearchDuration', data.duration)
        }
        commit('setResultList', data.results)
      } else {
        commit('setLock', false)
        throw new Error()
      }
    })
      .catch(err => {
        commit('setLock', false)
        this.dispatch('popups/pushPopup', { message: i18n.global.t('Search.getResultsErrorMessage') })
        console.error(err)
      })
  },
  nextPage ({ commit, state }) {
    // set params pageNb ++

    getSearchResults(state.query, state.params).then(data => {
      if (data.success) {
        commit('setResultList', data.results)
      } else {
        throw new Error()
      }
    })
      .catch(err => {
        this.dispatch('popups/pushPopup', { message: i18n.global.t('Search.getResultsErrorMessage') })
        console.error(err)
      })
  }
}

export const getters = {
  sortedHistoryList (state) {
    if (state.historyList === undefined) {
      return undefined
    } else {
      const sortedArray = []

      state.historyList.forEach(entry => {
        const index = sortedArray.map(item => item.day).indexOf(dayjs(entry.date).format('YYYY-MM-DD'))

        if (index !== -1) {
          sortedArray[index].searches.push(entry)
        } else {
          sortedArray.push({
            day: dayjs(entry.date).format('YYYY-MM-DD'),
            date: entry.date,
            searches: [entry]
          })
        }
      })

      // Ensure everything is sorted
      return sortWithString(sortedArray, true, 'date')
    }
  }
}
