import axios from 'axios'
import constants from '@/constants/appConstants'
import { getCookie } from '@/utils/browser.util'

export default {
  getProfileList,
  addUserProfile,
  deleteUserProfile,
  createProfile,
  deleteProfile
}

const PROFILE_PATH = '/userinfos.profile'

/**
 * Get all the profiles present in the application (fields: success, profileList)
 */
function getProfileList () {
  return axios.get(constants.JSON_WS_URL + PROFILE_PATH + '/get-profile-list', {
    params: {
      p_auth: getCookie('pauth')
    }
  }).then(response => response.data)
}

/**
 * Add a profile for the user (fields: success, profileAddedToUser)
 */
function addUserProfile (userId, profileId) {
  return axios.get(constants.JSON_WS_URL + PROFILE_PATH + '/add-user-profile', {
    params: {
      p_auth: getCookie('pauth'),
      userId: userId,
      profileId: profileId
    }
  }).then(response => response.data)
}

/**
 * remove the profile of the user (fields: success, profileDeletedToUser)
 */
function deleteUserProfile () {
  return axios.get(constants.JSON_WS_URL + PROFILE_PATH + '/delete-user-profile', {
    params: {
      p_auth: getCookie('pauth')
    }
  }).then(response => response.data)
}

/**
 * Create Profile in the application (fields: success, profile)
 */
function createProfile (profileName) {
  return axios.get(constants.JSON_WS_URL + PROFILE_PATH + '/create-profile', {
    params: {
      p_auth: getCookie('pauth'),
      name: profileName
    }
  }).then(response => response.data)
}

/**
 * Delete Profile in the application (fields: success, deletedProfile)
 */
function deleteProfile (profileId) {
  return axios.get(constants.JSON_WS_URL + PROFILE_PATH + '/delete-profile', {
    params: {
      p_auth: getCookie('pauth'),
      profileId: profileId
    }
  }).then(response => response.data)
}
