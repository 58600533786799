import axios from 'axios'
import constants from '@/constants/appConstants'
import { getCookie } from '@/utils/browser.util'

export default {
  deleteAccount,
  getSSOInfos,
  getUserInfos,
  getUserPicture,
  updateAccount
}

const USER_INFO_PATH = '/userinfos.userinfos'

/**
 * Get info to know if account is SSO or Liferay
 */
function getSSOInfos () {
  return axios.get(constants.JSON_WS_URL + USER_INFO_PATH + '/get-sso-infos', {
    params: {
      p_auth: getCookie('pauth')
    }
  }).then(response => response.data)
}

/**
 * Get all data we need about the current user... (fields: success, user)
 */
function getUserInfos () {
  return axios.get(constants.JSON_WS_URL + USER_INFO_PATH + '/get-current-user', {
    params: {
      p_auth: getCookie('pauth')
    }
  }).then(response => response.data)
}

/**
 * Retrieve the user's thumbnail for a given userId (fields: success, userPicture)
 */
function getUserPicture (userId) {
  return axios.get(constants.JSON_WS_URL + USER_INFO_PATH + '/get-user-picture', {
    params: {
      p_auth: getCookie('pauth'),
      userId: userId
    }
  }).then(response => response.data)
}

/**
 * Update user account
 */
function updateAccount (user) {
  const formData = new FormData()
  formData.append('p_auth', getCookie('pauth'))
  formData.append('password', user.password)
  formData.append('passwordCheck', user.passwordCheck)
  formData.append('profileId', user.profile.id)
  formData.append('city', user.address)
  if (user.pictureBlob) {
    formData.append('picture', user.pictureBlob, user.lastName + '-picture.png')
  } else {
    formData.append('picture', '')
  }

  return axios.post(constants.JSON_WS_URL + USER_INFO_PATH + '/update-account',
    formData
  ).then(response => response.data)
}

/**
 * Delete user account
 */
function deleteAccount () {
  return axios.get(constants.JSON_WS_URL + USER_INFO_PATH + '/delete-account', {
    params: {
      p_auth: getCookie('pauth')
    }
  }).then(response => response.data)
}
