import axios from 'axios'
import constants from '@/constants/appConstants'
import { getCookie } from '@/utils/browser.util'

export default {
  getPersonalLists,
  getPersonalListMembers,
  createPersonalList,
  updatePersonalList,
  deletePersonalList,
  getUsersCompletion
}

const CONTACTS_PATH = '/contacts.contacts'

/**
 * Get the user's personal lists (fields: success, personalLists)
 */
function getPersonalLists () {
  return axios.get(constants.JSON_WS_URL + CONTACTS_PATH + '/get-user-personal-lists', {
    params: {
      p_auth: getCookie('pauth')
    }
  }).then(response => response.data)
}

/**
 * Get the personal list members (fields: success, members)
 */
function getPersonalListMembers (list) {
  return axios.get(constants.JSON_WS_URL + CONTACTS_PATH + '/get-personal-list-members', {
    params: {
      p_auth: getCookie('pauth'),
      listeId: list.id
    }
  }).then(response => response.data)
}

/**
 * Create a personal list, (fields: success, personalList)
 */
function createPersonalList (name, memberIds) {
  return axios.get(constants.JSON_WS_URL + CONTACTS_PATH + '/create-personal-list', {
    params: {
      p_auth: getCookie('pauth'),
      name: name,
      memberIds: JSON.stringify(memberIds)
    }
  }).then(response => response.data)
}

/**
 * Update a personal list, (fields: success, personalList)
 */
function updatePersonalList (list, name, memberIds) {
  return axios.get(constants.JSON_WS_URL + CONTACTS_PATH + '/update-personal-list', {
    params: {
      p_auth: getCookie('pauth'),
      listeId: list.id,
      name: name,
      memberIds: JSON.stringify(memberIds)
    }
  }).then(response => response.data)
}

/**
 * Delete a personal list, (fields: success, deletedPersonalList)
 */
function deletePersonalList (list) {
  return axios.get(constants.JSON_WS_URL + CONTACTS_PATH + '/delete-personal-list', {
    params: {
      p_auth: getCookie('pauth'),
      listeId: list.id
    }
  }).then(response => response.data)
}

/**
 * Get auto-completion
 */
function getUsersCompletion (query) {
  return axios.get(constants.JSON_WS_URL + '/contacts.contacts' + '/get-users-completion', {
    params: {
      p_auth: getCookie('pauth'),
      query: query
    }
  }).then(response => response.data)
}
