import axios from 'axios'
import constants from '@/constants/appConstants'
import { getCookie } from '@/utils/browser.util'

export default {
  addCategory,
  updateCategory,
  deleteCategory,
  addIndicatorInCategory,
  updateIndicatorInCategory,
  deleteIndicatorInCategory,
  getProfileCatalog,
  getUserCatalog,
  getUnreadProfileIndicators,
  markAsReadNewProfileIndicator,
  markAsReadNewProfileIndicators
}

const CATALOG_PATH = '/indicator.catalog'

/**
 * Add category to catalog
 */
function addCategory (catalogId, category) {
  const formData = new FormData()
  formData.append('catalogId', catalogId)
  formData.append('title', category.title)
  formData.append('position', category.position)
  formData.append('p_auth', getCookie('pauth'))

  return axios.post(constants.JSON_WS_URL + CATALOG_PATH + '/add-category', formData)
    .then(response => response.data)
}

/**
 * Update category position and title
 */
function updateCategory (categoryId, title, position) {
  const formData = new FormData()
  formData.append('categoryId', categoryId)
  formData.append('title', title)
  formData.append('position', position)
  formData.append('p_auth', getCookie('pauth'))

  return axios.post(constants.JSON_WS_URL + CATALOG_PATH + '/update-category', formData)
    .then(response => response.data)
}

/**
 * Delete category from catalog
 */
function deleteCategory (categoryId) {
  return axios.get(constants.JSON_WS_URL + CATALOG_PATH + '/delete-category', {
    params: {
      p_auth: getCookie('pauth'),
      categoryId: categoryId
    }
  }).then(response => response.data)
}

/**
 * Add indicator to catalog's category
 */
function addIndicatorInCategory (indicatorId, categoryId, position, isPublic) {
  const formData = new FormData()
  formData.append('categoryId', categoryId)
  formData.append('indicatorId', indicatorId)
  formData.append('position', position)
  formData.append('isPublic', isPublic)
  formData.append('p_auth', getCookie('pauth'))

  return axios.post(constants.JSON_WS_URL + CATALOG_PATH + '/add-indicator-in-category', formData)
    .then(response => response.data)
}

/**
 * Update indicator position and visibility
 */
function updateIndicatorInCategory (categoryId, indicatorId, position, isPublic) {
  const formData = new FormData()
  formData.append('categoryId', categoryId)
  formData.append('indicatorId', indicatorId)
  formData.append('position', position)
  formData.append('isPublic', isPublic)
  formData.append('p_auth', getCookie('pauth'))

  return axios.post(constants.JSON_WS_URL + CATALOG_PATH + '/update-indicator-in-category', formData)
    .then(response => response.data)
}

/**
 * Delete indicator from catalog's category
 */
function deleteIndicatorInCategory (indicatorId, categoryId) {
  return axios.get(constants.JSON_WS_URL + CATALOG_PATH + '/delete-indicator-in-category', {
    params: {
      p_auth: getCookie('pauth'),
      categoryId,
      indicatorId
    }
  }).then(response => response.data)
}

/**
 * Get catalog for given profile (fields: success, catalog)
 */
function getProfileCatalog (profileId) {
  return axios.get(constants.JSON_WS_URL + CATALOG_PATH + '/get-profile-catalog', {
    params: {
      p_auth: getCookie('pauth'),
      profileId: profileId
    }
  }).then(response => response.data)
}

/**
 * Get user catalog
 */
function getUserCatalog () {
  return axios.get(constants.JSON_WS_URL + CATALOG_PATH + '/get-user-catalog', {
    params: {
      p_auth: getCookie('pauth')
    }
  }).then(response => response.data)
}

/**
 * Get new indicators added to user profile's default catalog since the last time (fields: success, unreadProfileIndicatorList)
 */
function getUnreadProfileIndicators () {
  return axios.get(constants.JSON_WS_URL + CATALOG_PATH + '/get-unread-profile-indicators', {
    params: {
      p_auth: getCookie('pauth')
    }
  }).then(response => response.data)
}

/**
 * Mark the new Profile's indicator as read for the current user (fields: success)
 */
function markAsReadNewProfileIndicators () {
  return axios.get(constants.JSON_WS_URL + CATALOG_PATH + '/mark-as-read-new-profile-indicators', {
    params: {
      p_auth: getCookie('pauth')
    }
  }).then(response => response.data)
}

/**
 * Mark the new Profile's indicator as read for the current user (fields: success)
 */
function markAsReadNewProfileIndicator (indicatorId) {
  return axios.get(constants.JSON_WS_URL + CATALOG_PATH + '/mark-as-read-new-profile-indicator', {
    params: {
      p_auth: getCookie('pauth'),
      indicatorId: indicatorId
    }
  }).then(response => response.data)
}
