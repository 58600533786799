import i18n from '@/i18n'

const spacesStructure = {
  private: {
    documentQuickOptions: [
      'download',
      'share'
    ],
    fields: [
      {
        position: 0, // the field place from left to right.
        name: 'name',
        label: i18n.global.t('AppCommonsLabels.Documents.nameLabel'),
        defaultDisplay: true,
        isPermanent: true,
        sort: {
          type: 'name'
        }
      },
      {
        position: 1,
        name: 'date',
        label: i18n.global.t('AppCommonsLabels.Documents.lastModifiedDateLabel'),
        defaultDisplay: true,
        sort: {
          type: 'lastModifiedDate'
        }
      }
    ]
  },
  post: {
    documentQuickOptions: [
      'download'
    ]
  }
}

export default spacesStructure
