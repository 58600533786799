import App from '@/Cadyco.vue'
import router from '@router/index.js'
import store from '@store/index.js'
import { createApp } from 'vue'
import '@/registerServiceWorker'
import i18n from '@/i18n'
import axios from 'axios'
import { Vue3Mq } from 'vue3-mq'
import moment from 'moment'
import dayjs from 'dayjs'
import fr from 'dayjs/locale/fr'

// Base components
import Autocomplete from '@components/Base/Autocomplete'
import Button from '@components/Base/Button'
import Checkbox from '@components/Base/Checkbox'
import Dropdown from '@components/Base/Dropdown'
import ErrorMessage from '@components/Base/ErrorMessage'
import Input from '@components/Base/Input'
import Popup from '@components/Base/Popup'
import RadioButton from '@components/Base/RadioButton'
import Spinner from '@components/Base/Spinner'
import TagItem from '@components/Base/TagItem'
import TagsInput from '@components/Base/TagsInput'
import UserPicture from '@components/Base/UserPicture'
import Window from '@components/Base/Window'

dayjs.locale(fr)

moment.updateLocale('fr', {
  calendar: {
    lastDay: '[' + i18n.global.t('Moment.yesterday') + '] LT',
    sameDay: 'LT',
    nextDay: '[' + i18n.global.t('Moment.tomorrow') + '] LT',
    lastWeek: 'dddd [' + i18n.global.t('Moment.at') + '] LT',
    nextWeek: 'LT',
    sameElse: 'L'
  }
})

// Don't warn about using the dev version of Vue in development.
// Vue.config.productionTip = process.env.NODE_ENV === 'production'

// If running inside Cypress...
if (process.env.VUE_APP_TEST === 'e2e') {
  // Ensure tests fail when Vue emits an error.
  // Vue.config.errorHandler = window.Cypress.cy.onUncaughtException
}

// Catch 403 error and redirect user to public home page
axios.interceptors.response.use(undefined, (error) => {
  if (error.response.status === 403 && error.response.config && !error.response.config.__isRetryRequest) {
    // if you ever get an unauthorized, redirect to login page
    if (store.getters['auth/isAuthenticated']) { // a session already exist
      document.location.href = store.state.auth.loginURL + '?redirect=' + encodeURIComponent(location.pathname + location.hash) // redirect on login page
    }
  }

  // Do something with response error
  return Promise.reject(error)
})

const app = createApp(App)
  .use(router)
  .use(store)
  .use(i18n)
  .use(Vue3Mq, { // Responsive breakpoints
    breakpoints: {
      phone: 0,
      tablet: 450,
      projectSizeLimit2: 800,
      projectSizeLimit1: 1050,
      desktop: 1300
    }
  })

// Register components globally
app.component('Autocomplete', Autocomplete)
app.component('Button', Button)
app.component('Checkbox', Checkbox)
app.component('Dropdown', Dropdown)
app.component('ErrorMessage', ErrorMessage)
app.component('Input', Input)
app.component('Popup', Popup)
app.component('RadioButton', RadioButton)
app.component('Spinner', Spinner)
app.component('TagItem', TagItem)
app.component('TagsInput', TagsInput)
app.component('UserPicture', UserPicture)
app.component('Window', Window)

app.mount('#app')

if (window.Cypress) {
  // only available during E2E tests
  window.app = app
  window.store = store
  window.router = router
}
