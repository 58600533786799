import axios from 'axios'
import constants from '@/constants/appConstants'
import { getCookie } from '@/utils/browser.util'

export default {
  createNews,
  editNews,
  getNewsList,
  getPublicNewsList,
  deleteNews
}

const NEWS_PATH = '/news.news'

/**
 * Create a news
 */
function createNews (news) {
  const formData = new FormData()
  formData.append('p_auth', getCookie('pauth'))
  formData.append('title', news.title)
  formData.append('releaseDate', news.releaseDate)
  formData.append('content', news.content)
  formData.append('linkDescription', news.linkDescription)
  formData.append('linkUrl', news.linkUrl)
  if (news.thumbnailBlob) {
    formData.append('thumbnail', news.thumbnailBlob, news.title + '-picture.png')
  } else {
    formData.append('thumbnail', '')
  }

  return axios.post(constants.JSON_WS_URL + NEWS_PATH + '/create-news',
    formData
  ).then(response => response.data)
}

/**
 * Edit a news
 */
function editNews (news) {
  const formData = new FormData()
  formData.append('p_auth', getCookie('pauth'))
  formData.append('newsId', news.newsId)
  formData.append('title', news.title)
  formData.append('releaseDate', news.releaseDate)
  formData.append('content', news.content)
  formData.append('linkDescription', news.linkDescription)
  formData.append('linkUrl', news.linkUrl)
  if (news.thumbnailBlob) {
    formData.append('thumbnail', news.thumbnailBlob, news.title + '-picture.png')
  } else {
    formData.append('thumbnail', '')
  }

  return axios.post(constants.JSON_WS_URL + NEWS_PATH + '/edit-news',
    formData
  ).then(response => response.data)
}

/**
 * Get all news
 */
function getNewsList () {
  return axios.get(constants.JSON_WS_URL + NEWS_PATH + '/get-news', {
    params: {
      p_auth: getCookie('pauth'),
      nbNews: 100
    }
  }).then(response => response.data)
}

/**
 * Get public news
 */
function getPublicNewsList () {
  return axios.get(constants.JSON_WS_URL + NEWS_PATH + '/public-get-news', {
    params: {
      p_auth: getCookie('pauth'),
      nbNews: 100
    }
  }).then(response => response.data)
}

/**
 * Delete a list of messages
 */
function deleteNews (newsId) {
  const formData = new FormData()
  formData.append('p_auth', getCookie('pauth'))
  formData.append('newsId', newsId)

  return axios.post(constants.JSON_WS_URL + NEWS_PATH + '/delete-news',
    formData
  ).then(response => response.data)
}
