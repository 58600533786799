import contacts from '@/api/contacts/contacts.service'
import communities from '@/api/communities/communities.service'

export const state = {
  userPersonalLists: [],
  userCommunities: [],
  selectedList: undefined
}

export const mutations = {
  updatePersonalLists (state, payload) {
    state.userPersonalLists = payload
  },
  updateCommunities (state, payload) {
    state.userCommunities = payload
  },
  updateSelectedList (state, personalList) {
    state.selectedList = personalList
  }
}

export const actions = {
  getUserPersonalLists ({ commit }, { idPersonalListToSelect, mq, router }) {
    contacts.getPersonalLists().then((data) => {
      if (data.success) {
        commit('updatePersonalLists', data.personalLists)

        // Update selected personalList
        if (!idPersonalListToSelect && data.personalLists.length > 0) {
          if (!mq || (!mq.phone && !mq.tablet)) {
            commit('updateSelectedList', data.personalLists[0]) // If not specified, select the first personalList of the list (only on desktop, let it to undefined on phone)
            router.push({ name: 'contacts', params: { listId: data.personalLists[0].id } }) // Update url to be consistent
          } else {
            commit('updateSelectedList', undefined)
          }
        } else {
          const index = this.getters['contact/personalListIndex'](idPersonalListToSelect)
          commit('updateSelectedList', data.personalLists[index])
          router.push({ name: 'contacts', params: { listId: idPersonalListToSelect } })
        }
      } else {
        console.error('Error while getting user\'s communities', data.error)
      }
    })
  },
  getUserCommunities ({ commit }) {
    communities.getUserCommunities().then((data) => {
      if (data.success) {
        commit('updateCommunities', data.communityList)
      } else {
        console.error('Error while getting user\'s communities', data.error)
      }
    })
  },
  selectList ({ commit }, list) {
    commit('updateSelectedList', list)
  }
}

export const getters = {
  allUserLists (state) {
    return [...state.userPersonalLists, ...state.userCommunities]
  },

  personalListIndex: (state) => (personalListId) => {
    return state.userPersonalLists.map(personalList => personalList.id).indexOf(personalListId)
  },

  communityListIndex: (state) => (communityId) => {
    return state.userCommunities.map(community => community.id).indexOf(communityId)
  },

  // Return the personalList in the list before the selected one
  previousList (state) {
    if (state.selectedList) {
      const personalListIndex = this.getters['contact/personalListIndex'](state.selectedList.id)
      const communityListIndex = this.getters['contact/communityListIndex'](state.selectedList.id)
      const concerns = (personalListIndex !== -1 ? state.userPersonalLists : undefined) || (communityListIndex !== -1 ? state.userCommunities : undefined)
      const index = (personalListIndex !== -1 ? personalListIndex : undefined) || (communityListIndex !== -1 ? communityListIndex : undefined)

      if (index > 0) {
        return concerns[index - 1]
      } else if (index === 0 && concerns.length > 1) {
        return concerns[index + 1]
      } else {
        return undefined
      }
    } else {
      return undefined
    }
  }
}
