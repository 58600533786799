import { createI18n } from 'vue-i18n'

import fr from '@/locales/fr'

const messages = {}
messages.fr = fr

const i18n = createI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || 'fr',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'fr',
  messages
})

export default i18n
