import { sortWithString } from '@/utils/array.util'
import newsService from '@/api/news/news.service'

export const state = {
  newsList: undefined
}

export const mutations = {
  addNews (state, news) {
    state.newsList.push(news)
  },
  deleteNews (state, newsId) {
    const i = state.newsList.map(item => item.newsId).indexOf(newsId)
    state.newsList.splice(i, 1)
  },
  setNewsList (state, newsList) {
    state.newsList = newsList
  },
  updateNews (state, news) {
    const i = state.newsList.map(item => item.newsId).indexOf(news.newsId)
    state.newsList[i].title = news.title
    state.newsList[i].releaseDate = news.releaseDate
    state.newsList[i].content = news.content
    state.newsList[i].linkDescription = news.linkDescription
    state.newsList[i].linkUrl = news.linkUrl
    state.newsList[i].thumbnail = news.thumbnail
  }
}

export const actions = {
  addNews ({ commit }, news) {
    newsService.createNews(news)
      .then(data => {
        if (data.success) {
          commit('addNews', data.createdNews)
        } else {
          throw new Error()
        }
      })
      .catch(err => {
        this.dispatch('popups/pushPopup', { message: 'ERROR' })
        console.error(err)
      })
  },
  deleteNews ({ commit }, newsId) {
    newsService.deleteNews(newsId)
      .then(data => {
        if (data.success) {
          commit('deleteNews', newsId)
        } else {
          throw new Error()
        }
      })
      .catch(err => {
        this.dispatch('popups/pushPopup', { message: 'ERROR' })
        console.error(err)
      })
  },
  initNewsList ({ commit }) {
    newsService.getNewsList()
      .then(data => {
        if (data.success) {
          commit('setNewsList', data.newsList)
        } else {
          throw new Error()
        }
      })
      .catch(err => {
        this.dispatch('popups/pushPopup', { message: 'ERROR' })
        console.error(err)
      })
  },
  updateNews ({ commit }, news) {
    newsService.editNews(news)
      .then(data => {
        if (data.success) {
          commit('updateNews', data.editedNews)
        } else {
          throw new Error()
        }
      })
      .catch(err => {
        this.dispatch('popups/pushPopup', { message: 'ERROR' })
        console.error(err)
      })
  }
}

export const getters = {
  sortedNewsList (state) {
    return (state.newsList === undefined) ? undefined : sortWithString(state.newsList, false, 'modifiedDate')
  }
}
