import axios from 'axios'
import { getCookie } from '@utils/browser.util'

export default {
  isSessionActive,
  logout
}

function isSessionActive (url) {
  return axios.get(url, {
    params: {
      p_auth: getCookie('pauth')
    }
  }).then(response => response.request)
}

function logout (url) {
  document.location.href = url
}
