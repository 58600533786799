import axios from 'axios'
import constants from '@/constants/appConstants'
import { getCookie } from '@/utils/browser.util'

export default {
  createEvent,
  editEvent,
  getEventList,
  getPublicEventList,
  deleteEvent
}

const EVENT_PATH = '/news.event'

/**
 * Create a event
 */
function createEvent (event) {
  const formData = new FormData()
  formData.append('p_auth', getCookie('pauth'))
  formData.append('title', event.title)
  formData.append('releaseDate', event.releaseDate)
  formData.append('eventDate', event.eventDate)
  formData.append('location', event.location)
  formData.append('content', event.content)
  formData.append('linkDescription', event.linkDescription)
  formData.append('linkUrl', event.linkUrl)
  if (event.thumbnailBlob) {
    formData.append('thumbnail', event.thumbnailBlob, event.title + '-picture.png')
  } else {
    formData.append('thumbnail', '')
  }

  return axios.post(constants.JSON_WS_URL + EVENT_PATH + '/create-event',
    formData
  ).then(response => response.data)
}

/**
 * Edit a event
 */
function editEvent (event) {
  const formData = new FormData()
  formData.append('p_auth', getCookie('pauth'))
  formData.append('eventId', event.eventId)
  formData.append('title', event.title)
  formData.append('releaseDate', event.releaseDate)
  formData.append('eventDate', event.eventDate)
  formData.append('location', event.location)
  formData.append('content', event.content)
  formData.append('linkDescription', event.linkDescription)
  formData.append('linkUrl', event.linkUrl)
  if (event.thumbnailBlob) {
    formData.append('thumbnail', event.thumbnailBlob, event.title + '-picture.png')
  } else {
    formData.append('thumbnail', '')
  }

  return axios.post(constants.JSON_WS_URL + EVENT_PATH + '/edit-event',
    formData
  ).then(response => response.data)
}

/**
 * Get all event
 */
function getEventList () {
  return axios.get(constants.JSON_WS_URL + EVENT_PATH + '/get-events', {
    params: {
      p_auth: getCookie('pauth'),
      nbEvents: 100
    }
  }).then(response => response.data)
}

/**
 * Get public events
 */
function getPublicEventList () {
  return axios.get(constants.JSON_WS_URL + EVENT_PATH + '/public-get-events', {
    params: {
      p_auth: getCookie('pauth'),
      nbEvents: 100
    }
  }).then(response => response.data)
}

/**
 * Delete a list of messages
 */
function deleteEvent (eventId) {
  const formData = new FormData()
  formData.append('p_auth', getCookie('pauth'))
  formData.append('eventId', eventId)

  return axios.post(constants.JSON_WS_URL + EVENT_PATH + '/delete-event',
    formData
  ).then(response => response.data)
}
