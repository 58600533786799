const PORTAL_CTX = '/portal'
const JSON_WS_URL = PORTAL_CTX + '/api/jsonws'

const messagingInboxFolderType = 1
const messagingDraftFolderType = 2
const messagingSentFolderType = 3
const messagingTrashFolderType = 4
const messagingPersonalFolderType = 5

const entityNameMaxSize = {
  file: 255,
  folder: 255
}

const nbPostToLoadAtOneTime = 25
const nbCommentsToLoadAtOneTime = 10

// time in ms
const popupDurationTime = 4000
const timeBeforeSideMenuExtension = 250

export {
  entityNameMaxSize,
  messagingInboxFolderType,
  messagingDraftFolderType,
  messagingSentFolderType,
  messagingTrashFolderType,
  messagingPersonalFolderType,
  nbPostToLoadAtOneTime,
  nbCommentsToLoadAtOneTime,
  JSON_WS_URL,
  popupDurationTime,
  PORTAL_CTX,
  timeBeforeSideMenuExtension
}

export default {
  entityNameMaxSize,
  messagingInboxFolderType,
  messagingDraftFolderType,
  messagingSentFolderType,
  messagingTrashFolderType,
  messagingPersonalFolderType,
  nbPostToLoadAtOneTime,
  nbCommentsToLoadAtOneTime,
  JSON_WS_URL,
  popupDurationTime,
  timeBeforeSideMenuExtension
}
